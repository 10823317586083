export default {
  title: 'Mina sidor',
  generalInfo: 'Information om tidigare byten av elavtal rensas i enlighet med GDPR. Byten gjorda på Elsklings företagstjänst visas ej.',
  applications: {
    ongoing: 'Pågående byten',
    paused: 'Kontakta kundtjänst',
    historic: {
      title: 'Tidigare byten',
      info: 'Observera att gamla avtal rensas efter en viss tid i enlighet med GDPR-lagstiftning.'
    },
    cancelled: 'Avbrutna byten',
    none: 'Här kommer dina pågående och tidigare byten synas.'
  },
  logout: 'Logga ut',
  menu: {
    title: 'Mina sidor',
    links: [
      {
        label: 'Mina byten',
        key: 'MyApplications'
      },
      {
        label: 'Mina anläggningar',
        key: 'MyProperties'
      },
      {
        label: 'Prisbevakningar',
        key: 'PriceWatch'
      }
    ]
  }
}
