export default {
  login: {
    title: 'Logga in på Mina sidor'
  },
  buttons: {
    login: 'Logga in',
    cancelEid: 'Avbryt',
    openBankid: 'Öppna BankID'
  },
  messages: {
    eidIsDown: 'För närvarande pågår en driftstörning som påverkar BankID. Arbete pågår för att lösa problemet.'
  },
  menu: {
    label: 'Mina sidor'
  },
  steps: [
    'Öppna appen för BankID.',
    'Tryck på QR-symbolen i appen för BankID.',
    'Rikta kameran mot QR-koden ovan.',
    'Följ instruktionerna i appen för att logga in.'
  ],
  sameDevice: {
    title: 'Har du BankID på den här enheten?',
    link: 'Om du har BankID på den här enheten kan du klicka på "Öppna BankID" här nedanför för att logga in.'
  }
}
