<template>
  <div class="route">
    <routes-page
      v-if="routeComponent === 'routesPage'"
      @loaded="loaded"
    />

    <example-route
      v-else-if="routeComponent === 'exampleRoute'"
      @loaded="loaded"
    />

    <sdk-main-page
      v-else-if="routeComponent === 'sdkMainPage'"
      :page="routeContent"
      :site="routeSite"
      @loaded="loaded"
    />

    <store
      v-else-if="routeComponent === 'store'"
      :content="routeContent"
      :container-id="containerId"
      @loaded="loaded"
    />

    <contact
      v-else-if="routeComponent === 'contact'"
      :content="routeContent"
      @loaded="loaded"
    />

    <mediation-batch
      v-else-if="routeComponent === 'mediationBatch'"
      :content="routeContent"
      @loaded="loaded"
    />

    <error
      v-else-if="routeComponent === 'error'"
      :content="routeContent"
      @loaded="loaded"
    />

    <elskling-header
      v-else-if="routeComponent === 'elsklingHeader'"
      :content="routeMenu"
      @loaded="loaded"
    />

    <elskling-footer
      v-else-if="routeComponent === 'elsklingFooter'"
      :content="routeMenu"
      @loaded="loaded"
    />

    <elskling-my-pages
      v-else-if="routeComponent === 'elsklingMyPages'"
      :content="routeContent"
      @loaded="loaded"
    />

    <price-watch-unsubscribe
      v-else-if="routeComponent === 'priceWatchUnsubscribe'"
      @loaded="loaded"
    />

    <zip-code-form
      v-else-if="routeComponent === 'zipCodeForm'"
      :content="routeContent"
      @loaded="loaded"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getProp } from '@ocp-zmarta/zmarta-cl'

  export default {
    name: 'Routes',
    components: {
      RoutesPage: () => import(/* webpackChunkName: 'routes-page' */
        './RoutesPage.vue'),
      ExampleRoute: () => import(/* webpackChunkName: 'example-route' */
        './ExampleRoute'),
      SdkMainPage: () => import(/* webpackChunkName: 'sdk-main-page' */
        '@content-sdk/layout/MainPage'),
      Store: () => import(/* webpackChunkName: 'store' */
        './Store'),
      Contact: () => import(/* webpackChunkName: 'contact' */
        './Contact'),
      MediationBatch: () => import(/* webpackChunkName: 'mediation-batch' */
        './MediationBatch'),
      Error: () => import(/* webpackChunkName: 'error' */
        './Error'),
      ElsklingHeader: () => import(/* webpackChunkName: 'elskling-header' */
        './elskling/Header'),
      ElsklingFooter: () => import(/* webpackChunkName: 'elskling-footer' */
        './elskling/Footer'),
      ZipCodeForm: () => import(/* webpackChunkName: 'zip-code-form' */
        './ZipCodeForm'),
      ElsklingMyPages: () => import(/* webpackChunkName: 'elskling-my-pages' */
        './elskling/MyPages.vue'),
      PriceWatchUnsubscribe: () => import(/* webpackChunkName: 'price-watch-unsubscribe' */
        './elskling/PriceWatchUnsubscribe.vue')
    },
    props: {
      containerId: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      routeComponent () {
        return getProp(this.getRoute, 'component')
      },
      routeProps () {
        return getProp(this.getRoute, 'props')
      },
      routeSite () {
        return getProp(this.routeProps, 'site')
      },
      routeContent () {
        return getProp(this.routeProps, 'content')
      },
      routeMenu () {
        return getProp(this.getRoute, 'menu')
      }
    },
    methods: {
      loaded () {
        this.$emit('loaded')
      }
    }
  }
</script>
