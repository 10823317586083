<template>
  <header class="elskling-menu-header">
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Login                                                                                  -->
    <!-- -------------------------------------------------------------------------------------- -->

    <site-information />
    <login />
    <auth-handler />

    <nav class="flex-container-header">
      <a
        class="logo"
        href="/"
      >
        <zg-logo brand="elskling" />
      </a>

      <ul
        v-show="!hideDistractions && desktopLayout"
        class="navigation-links"
      >
        <li
          v-for="links in navigationLinks"
          :key="getProp(links, 'contentId')"
          :ref="getProp(links, 'contentId')"
          :class="{
            'navigation-link--active': subNavigation === getProp(links, 'contentId'),
            'navigation-link--current': getProp(links, 'link', 'href') === getPath
          }"
          @mouseenter="hideOrShowSubNavigation(getProp(links, 'contentId'))"
          @mouseleave="hideOrShowSubNavigation()"
        >
          <a
            class="navigation-link"
            :href="getProp(links, 'link', 'href')"
          >
            {{ getProp(links, 'title') || getProp(links, 'link', 'text') }}

            <zg-icon
              v-if="isNotEmpty(getProp(links, 'items'))"
              icon="chevron"
              size="small"
              :rotate="subNavigation === getProp(links, 'contentId') ? '180' : '0'"
            />
          </a>

          <ul
            v-if="isNotEmpty(getProp(links, 'items'))"
            v-show="subNavigation === getProp(links, 'contentId')"
            class="navigation-links-items"
          >
            <li
              v-for="items in getProp(links, 'items')"
              :key="getProp(items, 'contentId')"
            >
              <a
                :href="getProp(items, 'link', 'href')"
                :class="['navigation-links-item', {
                  'navigation-links-item--current': getProp(items, 'link', 'href') === getPath
                }]"
              >
                {{ getProp(items, 'link', 'text') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <div class="flex-container">
        <a
          :href="`tel:${getProp(phone, getProduct, 'number')}`"
        >
          <zg-icon
            icon="phone"
            size="xsmall"
          />
        </a>
        <client-only>
          <p
            v-if="getScreenWidth === 'desktop'"
            class="phone-text"
          >
            {{ getProp(phone, getProduct, 'number') }}
          </p>

          <login-button
            v-if="showMyPages"
            :content="content"
          />
        </client-only>

        <div v-show="!hideDistractions && !desktopLayout">
          <div
            class="grid-icon"
            @click="showMenu = !showMenu"
          >
            <zg-icon
              :icon="showMenu ? 'close' : 'menu'"
              size="small"
            />
          </div>

          <zg-transition-fade>
            <div
              v-show="showMenu"
              class="menu"
            >
              <div
                class="menu--overlay"
                @click="showMenu = false"
              />
              <div class="menu--content">
                <ul>
                  <li v-if="showMyPages">
                    <my-pages-menu :content="content" />
                  </li>

                  <li
                    v-for="links in navigationLinks"
                    :key="getProp(links, 'contentId')"
                  >
                    <a
                      :href="isNotEmpty(getProp(links, 'items')) ? null : getProp(links, 'link', 'href')"
                      @click="hideOrShowSubNavigation(getProp(links, 'contentId'))"
                    >
                      <h4 :class="['menu-header', { 'menu-header--active': showSubNavigation && subNavigation === getProp(links, 'contentId') }]">
                        {{ getProp(links, 'title') || getProp(links, 'link', 'text') }}

                        <zg-icon
                          v-if="isNotEmpty(getProp(links, 'items'))"
                          icon="chevron"
                          :rotate="showSubNavigation && subNavigation === getProp(links, 'contentId') ? '180' : '0'"
                          size="small"
                        />
                      </h4>
                    </a>

                    <ul
                      v-if="isNotEmpty(getProp(links, 'items'))"
                      v-show="showSubNavigation && subNavigation === getProp(links, 'contentId')"
                      class="sub-menu"
                    >
                      <li v-if="getProp(links, 'title')">
                        <a
                          :class="{ 'sub-menu-link--current': getProp(links, 'link', 'href') === getPath }"
                          :href="getProp(links, 'link', 'href')"
                        >
                          {{ getProp(links, 'link', 'text') }}
                        </a>
                      </li>
                      <li
                        v-for="items in getProp(links, 'items')"
                        :key="getProp(items, 'contentId')"
                      >
                        <a
                          :class="{ 'sub-menu-link--current': getProp(items, 'link', 'href') === getPath }"
                          :href="getProp(items, 'link', 'href')"
                        >
                          {{ getProp(items, 'link', 'text') }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </zg-transition-fade>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import Login from '../../elements/login/se/Login.vue'
  import { ZgLogo } from '@zc/components/ZgLogo'
  import { ZgIcon } from '@zc/components/ZgIcon'
  import { ZgTransitionFade } from '@zc/components/ZgTransitionFade'
  import { mapGetters } from 'vuex'
  import { getProp, getElement, isEmpty, size, getCookie, setCookie } from '@ocp-zmarta/zmarta-cl'
  import { isNotEmpty } from '@utils'
  import ClientOnly from 'vue-client-only'
  import LoginButton from '../../elements/login/LoginButton'
  import AuthHandler from '../../elements/AuthHandler'
  import MyPagesMenu from '../../elements/myPages/VerticalMenuButton'
  import requests from '../../../requests'
  import SiteInformation from './SiteInformation.vue'

  export default {
    name: 'ElsklingHeader',
    components: {
      ClientOnly,
      ZgLogo,
      ZgIcon,
      ZgTransitionFade,
      LoginButton,
      AuthHandler,
      MyPagesMenu,
      Login,
      SiteInformation
    },
    props: {
      content: {
        type: Object,
        required: true
      },
      product: {
        type: String,
        required: false,
        default: 'consumer'
      }
    },
    data: () => ({
      showMenu: false,
      showSubNavigation: false,
      subNavigation: null,
      navigationLinksWidth: null,
      roomForLinks: true
    }),
    computed: {
      ...mapGetters({
        getQuery: 'router/getQuery',
        getRoute: 'router/getRoute',
        getScreenWidth: 'utils/getScreenWidth',
        getCountry: 'utils/getCountry',
        getFeatures: 'features/getFeatures',
        getUser: 'user/getUser',
        getUserAttributes: 'utils/getUserAttributes'
      }),
      getPath () {
        return getProp(this.getRoute, 'path')
      },
      hideDistractions () {
        return getProp(this.getQuery, 'hideDistractions') === 'true'
      },
      isInsuranceFlow () {
        return getProp(this.getQuery, 'isInsurance') === 'true'
      },
      desktopLayout () {
        return this.getScreenWidth === 'desktop' && this.roomForLinks
      },
      getProduct () {
        return getProp(this.getQuery, 'product') || this.product
      },
      navigationLinks () {
        return getProp(this.content, 'menuNavigation') ?? []
      },
      showMyPages () {
        return this.getCountry === 'se' &&
          !(this.getUserAttributes.isiPad && this.getUserAttributes.isSafari) &&
          !this.getPath.startsWith('/forsakring')
      },
      phone () {
        const resources = getProp(this.content, 'microCopy', 'resources')
        function findResource (contentId) {
          return resources?.find(r => r.contentId === contentId)?.value
        }

        return {
          consumer: {
            text: this.isInsuranceFlow ? findResource('19Xqp5bX4OsZJet6qd4XJw') : findResource('7eCcNGprsM96TlmMLzK1Df'),
            number: this.isInsuranceFlow ? findResource('6BSfHqBA3VRHckoWbopuDC') : findResource('41gABmKGvenRk3CEGVzbld')
          },
          business: {
            text: findResource('4NOhVG6soCKAxQAmZuU0sf'),
            number: findResource('5c0Nh8N9wvzsGfUd7ht1do')
          }
        }
      }
    },
    watch: {
      getScreenWidth (v) {
        if (v === 'desktop' && !this.hideDistractions) {
          this.$nextTick(() => {
            this.navigationLinksToShow()
          })
        }
      },
      showMenu (v) {
        if (!this.desktopLayout && v) {
          document.body.style.position = 'fixed'
        } else {
          document.body.style.position = 'unset'
        }
      }
    },
    mounted () {
      /**
       * The event is triggered if the user accepts the use of cookies. The event is also triggered if the user has consented at an earlier visit to the website.
       * https://www.cookiebot.com/en/developer/#h-event-handling
      */
      window.addEventListener('CookiebotOnAccept', this.trackPageView)

      // This is needed as cookiebot is not enabled in test
      if (!this.PRODUCTION) {
        this.postPageView()
      }

      if (!this.hideDistractions) {
        window.addEventListener('resize', this.navigationLinksToShow)

        window.addEventListener('elskling-menu:logged-in', () => this.updateIsLoggedInSessionStorage(true))
        window.addEventListener('elskling-menu:logged-out', () => this.updateIsLoggedInSessionStorage(false))

        this.navigationLinksToShow()
      }
      this.$emit('loaded')
    },
    destroyed () {
      if (!this.hideDistractions) {
        window.removeEventListener('resize', this.navigationItemsToShow)
      }
      window.removeEventListener('elskling-menu:logged-in', () => this.updateIsLoggedInSessionStorage(true))
      window.removeEventListener('elskling-menu:logged-out', () => this.updateIsLoggedInSessionStorage(false))
    },
    methods: {
      getProp,
      isEmpty,
      isNotEmpty,
      size,
      getElement,
      navigationLinksToShow () {
        if (this.getScreenWidth !== 'desktop' || this.hideDistractions) return

        const navigationLinks = getElement('.navigation-links').getBoundingClientRect()
        if (navigationLinks.width > 0) this.navigationLinksWidth = navigationLinks.width

        const phoneLeftEdge = getElement('.flex-container')?.getBoundingClientRect().left
        const logoRightEdge = getElement('.logo')?.getBoundingClientRect().right
        if (this.roomForLinks) {
          if (phoneLeftEdge - navigationLinks.right < 1) this.roomForLinks = false
        } else {
          if ((phoneLeftEdge - logoRightEdge + 24) > this.navigationLinksWidth) this.roomForLinks = true
        }
      },
      hideOrShowSubNavigation (v) {
        if (v && v !== this.subNavigation) {
          this.showSubNavigation = true
          this.subNavigation = v
        } else {
          this.showSubNavigation = !this.showSubNavigation

          if (this.desktopLayout) {
            setTimeout(() => {
              if (!this.showSubNavigation) {
                this.subNavigation = null
              }
            }, 500)
          } else {
            this.subNavigation = null
          }
        }
      },
      updateIsLoggedInSessionStorage (isLoggedIn) {
        const vuexStringified = sessionStorage.getItem('vuex')
        if (!vuexStringified) {
          sessionStorage.setItem('vuex', JSON.stringify({ global: { isLoggedIn } }))
        } else {
          const vuexJson = JSON.parse(vuexStringified)
          vuexJson.global.isLoggedIn = isLoggedIn
          sessionStorage.setItem('vuex', JSON.stringify(vuexJson))
        }
      },
      async postPageView () {
        try {
          if (!getCookie('zCid')) {
            const uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            )
            setCookie('zCid', uuid, 30)
          }

          await requests.gateway.trackingEvents.pageView(window.location.href)
        } catch {}
      },
      trackPageView () {
        if (window.Cookiebot?.consent?.marketing) {
          this.postPageView()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.elskling-menu-header {
  position: fixed;
  height: rem(56px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color(white);
  z-index: 21;

  a {
    border-bottom: none;
  }
}

.flex-container-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: rem(20px);
  padding-right: rem(20px);

  @include mq("medium") {
    padding-left: rhythm("double");
    padding-right: rhythm("double");
  }
}

.logo {
  height: inherit;

  svg {
    height: inherit;
    width: auto;
    padding-top: rhythm("half");
    padding-bottom: rhythm("half");

    @include mq("large") {
      padding-top: rhythm("xsmall");
      padding-bottom: rhythm("xsmall");
    }
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.menu {
  position: fixed;
  inset: rem(56px) 0 0;
  overflow-y: scroll;
  z-index: 99;

  &--overlay {
    position: fixed;
    top: inherit;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(white);
  }

  &--content {
    position: absolute;
    inset: 0 0 auto;
    background-color: color(white);
    border-top: 1px solid rgb(0 0 0 / 20%);
    z-index: 1;

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    .menu-header {
      display: flex;
      justify-content: space-between;
      padding: rhythm("small");
      margin-bottom: 0;
      border-bottom: 1px solid rgb(0 0 0 / 20%);
      cursor: pointer;

      @include mq("medium") {
        @include type-title-s;

        margin-bottom: 0;
      }

      &--active {
        background-color: color(beige);
      }
    }

    a {
      display: block;
    }
  }
}

.sub-menu {
  @include type-title-s;

  background-color: color(beige);
  padding-top: rhythm("small");
  padding-bottom: rhythm("small");
  margin-bottom: 0;

  a {
    display: inline-block;
    padding: rhythm("small") rhythm("medium");
  }

  &-link--current {
    background-color: color(white);
    border-radius: 0 6.1875rem 6.1875rem 0;
  }
}

.flex-container {
  display: flex;
  align-items: center;
  column-gap: rhythm("small");
  margin-left: auto;

  > a {
    display: flex;
  }

  @include mq(large) {
    height: inherit;
  }
}

.navigation-links {
  @include type-title-xs;

  display: flex;
  padding-top: rhythm("xsmall");
  padding-left: rhythm("small");
  margin-bottom: 0;
  overflow: hidden;

  > li {
    height: 100%;
    padding-bottom: rhythm("xsmall");
  }

  a:hover {
    color: color(brand-500);

    svg {
      color: color(brand-500);
    }
  }
}

.navigation-link {
  display: flex;
  align-items: center;
  column-gap: rhythm("xsmall");
  padding-right: rhythm("small");
  padding-left: rhythm("small");
  white-space: nowrap;

  &--active {
    background-color: color(beige);
    border-radius: 20px 20px 0 0;
  }

  &--current {
    background-color: color(brand-100);
    border-radius: 20px 20px 0 0;
  }
}

.navigation-links-items {
  position: fixed;
  top: rem(56px);
  background-color: color(beige);
  border: 1px solid color(secondary-500, 0.01);
  border-radius: 0 0 20px 20px;
  padding-top: rhythm("small");
  padding-bottom: rhythm("xsmall");
}

.navigation-links-item {
  @include type-title-xs;

  display: flex;
  align-items: center;
  column-gap: rhythm("xsmall");
  cursor: pointer;
  padding: rhythm("small") rhythm("base");
  margin-right: rhythm("small");

  &--current {
    background-color: color(white);
    border-radius: 0 6.1875rem 6.1875rem 0;
  }
}

.navigation-more-icon {
  display: flex;
  align-items: center;
  padding-right: rhythm("small");
  padding-left: rhythm("small");
}

.navigation-more-links {
  display: flex;
  flex-direction: row-reverse;
  column-gap: rhythm("xsmall");
  position: fixed;
  background-color: color(beige);
  border: 1px solid color(secondary-500, 0.01);
  border-radius: 0 0 20px 20px;
  padding: rhythm("small");
  white-space: nowrap;

  &--right-0 {
    right: 0;
  }

  .navigation-links-items {
    position: relative;
    border: none;
  }

  .list-title {
    border-bottom: solid 1px color(gray-300);
  }
}

.flex-container-header,
.navigation-links,
.navigation-link,
.navigation-more-icon {
  height: inherit;
}

.grid-icon {
  display: grid;
  justify-items: center;
  cursor: pointer;

  p {
    @include type-body-s;

    color: white;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.phone-text {
  @include type-title-xs;

  margin-right: rhythm("base");
  margin-bottom: 0;
  white-space: nowrap;

  @include mq("medium") {
    margin-right: 0;
  }
}
</style>
