import { isArray, isBool } from '@ocp-zmarta/zmarta-cl'
import { isNotEmpty } from '@utils'

export default {
  namespaced: true,
  state: {
    loading: true,
    hasProperties: false,
    savedProperties: []
  },
  getters: {
    getProperties: state => state
  },
  mutations: {
    addProperty (state, v) {
      state.savedProperties.push(v)
    },
    updateValue (state, { index, key, value }) {
      if (!isNaN(index) && index < state.savedProperties?.length && state.savedProperties[index][key] !== undefined) {
        state.savedProperties[index][key] = value
      }
    },
    setLoading (state, v) {
      if (isBool(v)) {
        state.loading = v
      }
    },
    setHasProperties (state, v) {
      if (isBool(v)) {
        state.hasProperties = v
      }
    },
    setSavedProperties (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.savedProperties = v
      }
    }
  }
}
