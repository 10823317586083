export default {
  title: 'Mina anläggningar',
  addPropertyButton: 'Lägg till anläggning',
  form: {
    title: 'Lägg till anläggning',
    buttons: {
      cancel: 'Avbryt',
      submit: 'Lägg till',
      update: 'Spara ändringar'
    },
    inputs: {
      primary: 'Primär anläggning',
      zipCode: {
        label: 'Postnummer',
        errors: {
          empty: 'Du måste fylla i postnummer',
          incorrectLength: 'Postnumret måste ha 5 siffror',
          invalid: 'Vi kunde inte hitta det här postnumret'
        }
      },
      address: {
        label: 'Adress',
        errors: {
          empty: 'Du måste fylla i adress'
        }
      },
      city: {
        label: 'Postort'
      },
      netId: {
        label: 'Områdes-ID',
        errors: {
          empty: 'Du måste fylla i områdes-ID',
          incorrectLength: 'Områdes-ID:t måste innehålla 3 bokstäver'
        }
      },
      facilityId: {
        label: 'Anläggnings-ID',
        errors: {
          empty: 'Du måste fylla i anläggnings-ID',
          incorrectLength: 'Anläggnings-ID:t måste innehålla 18 siffror',
          notUnique: 'Anläggnings-ID:t måste vara unikt'
        }
      },
      yearlyConsumption: {
        label: 'Årsförbrukning (valfri)',
        unit: 'kWh'
      },
      houseType: {
        label: 'Boendetype',
        options: {
          HOUSE: 'Villa / radhus',
          APARTMENT: 'Lägenhet / stuga'
        }
      }
    }
  }
}
