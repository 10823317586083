import { formField, getRegex } from '@ocp-zmarta/zmarta-cl'

const field = formField()
field.type = 'tel'
field.attrs = [{ pattern: getRegex({ type: 'ssn', country: 'se' }) }, { maxlength: 13 }]
field.format = 'ssn'
field.validation = { required: true, ssn: true, regex: getRegex({ type: 'ssn', country: 'se' }) }
field.blurAction = 'form/ssnBlur'
field.fullWidth = true

export default field
