<template>
  <footer>
    <zg-border
      border="clouds"
      border-color="brand-100"
      :background-color="beigeBorderBackground ? 'beige' : 'white'"
    />

    <nav>
      <div class="inner-container list">
        <ul
          v-for="navigation in getProp(content, 'footerNavigation')"
          :key="getProp(navigation, 'contentId')"
        >
          <li
            class="labels"
            @click="openLinks(getProp(navigation, 'contentId'))"
          >
            {{ getProp(navigation, 'title') }}

            <zg-icon
              v-show="getScreenWidth === 'mobile'"
              icon="chevron"
              :rotate="linksOpen === getProp(navigation, 'contentId') ? '180' : '0'"
              size="small"
            />
          </li>

          <zg-transition-fade>
            <ul v-show="linksOpen === getProp(navigation, 'contentId') || getScreenWidth !== 'mobile'">
              <li
                v-for="links in getProp(navigation, 'links')"
                :key="getProp(links, 'contentId')"
                class="links"
              >
                <a
                  :href="getProp(links, 'href')"
                  :target="getProp(links, 'target')"
                  v-html="domPurify(getProp(links, 'text'))"
                />
              </li>
            </ul>
          </zg-transition-fade>
        </ul>
      </div>
    </nav>

    <div class="contact">
      <rich-text :body="footerContent" />
    </div>
  </footer>
</template>

<script>
  import { ZgBorder } from '@zc/components/ZgBorder'
  import { ZgTransitionFade } from '@zc/components/ZgTransitionFade'
  import { ZgIcon } from '@zc/components/ZgIcon'
  import RichText from '@content-sdk/components/RichText'
  import { getProp } from '@ocp-zmarta/zmarta-cl'
  import { domPurify } from '@utils'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ElsklingFooter',
    components: {
      ZgBorder,
      ZgTransitionFade,
      ZgIcon,
      RichText
    },
    props: {
      content: {
        type: Object,
        required: true
      },
      product: {
        type: String,
        required: false,
        default: 'consumer'
      },
      beigeBackground: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      linksOpen: null
    }),
    computed: {
      ...mapGetters('router', ['getQuery']),
      ...mapGetters('utils', ['getScreenWidth']),
      getProduct () {
        return getProp(this.getQuery, 'product') || this.product
      },
      resources () {
        return getProp(this.content, 'microCopy', 'resources')
      },
      footerContent () {
        const content = getProp(this.content, 'footerContent')

        if (content) {
          content[0].value = getProp(content, 0, 'value')
            ?.replace('{{phone}}', this.phone)
            ?.replace('{{email}}', this.email)
        }

        return content ?? []
      },
      phone () {
        const phoneNumbers = {
          consumer: this.resources?.find(r => r.contentId === '41gABmKGvenRk3CEGVzbld')?.value,
          business: this.resources?.find(r => r.contentId === '5c0Nh8N9wvzsGfUd7ht1do')?.value
        }
        return `<a href="tel:${phoneNumbers[this.getProduct]}">${phoneNumbers[this.getProduct]}</a>`
      },
      email () {
        const emails = {
          consumer: this.resources?.find(r => r.contentId === 'v8fGIhvGs1DD5WvPKkPCX')?.value,
          business: this.resources?.find(r => r.contentId === '3vHziMvzLigvVa7Xkw3Wtj')?.value
        }
        return `<a href="mailto:${emails[this.getProduct]}">${emails[this.getProduct]}</a>`
      },
      beigeBorderBackground () {
        return this.beigeBackground || getProp(this.getQuery, 'background') === 'beige'
      }
    },
    methods: {
      getProp,
      domPurify,
      openLinks (contentId) {
        this.linksOpen = this.linksOpen === contentId
          ? null
          : contentId
      }
    }
  }
</script>

<style lang="scss" scoped>
nav {
  background-color: color(brand-100);

  a {
    border-bottom: none;
  }
}

ul {
  text-decoration: none;
  padding-left: 0;
}

li {
  list-style: none;
}

.labels {
  @include type-title-s;

  display: flex;
  column-gap: rhythm("xsmall");
  list-style: none;
  margin-bottom: rhythm("medium");
  cursor: pointer;

  @include mq("medium") {
    display: inline-block;
    margin-bottom: rhythm("medium");
    cursor: unset;
  }
}

.links {
  @include type-body-m;

  margin-bottom: rhythm("medium");
}

.contact {
  background-color: color(brand-500);
  padding: rhythm("base") rhythm("double") rhythm("half");

  ::v-deep p {
    @include type-body-s;

    color: color(white);

    &:first-child {
      margin-bottom: 1.35rem;
    }
  }

  ::v-deep a {
    color: color(white);
    border-color: color(white);
    margin-bottom: 0;
  }
}

.inner-container {
  max-width: rem(1090px);
  padding: 0 rhythm("base") rhythm("small");
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;

  @include mq("medium") {
    padding-top: rhythm("large");
    padding-bottom: rhythm("large");
  }
}

.list {
  @include mq("medium") {
    display: flex;
    justify-content: space-between;
    column-gap: rhythm("small");
    flex-wrap: wrap;
  }
}
</style>
