import packageJson from '../../package.json'

const internalPrefix = `${packageJson.name}`

const serviceAuthHeaders = (country) => ({
  'x-auth-market': country,
  'x-auth-vertical': 'electricity',
  'x-auth-role': 'customer'
})

export default {
  get: (url) => ({
    method: 'GET',
    url
  }),
  auth: (country) => ({
    bank: {
      list: () => ({
        method: 'GET',
        url: '/service/auth/api/v1/bank/list',
        headers: serviceAuthHeaders(country)
      })
    },
    eid: {
      initiate: ({ eidProvider }) => ({
        method: 'POST',
        url: '/service/auth/api/v1/eid/initiate',
        headers: {
          ...serviceAuthHeaders(country),
          ...eidProvider ? { 'x-auth-eid-provider': eidProvider } : {}
        },
        data: {
          axiosDoesntLikeAnEmptyBodyForSomeReason: true
        }
      }),
      collect: ({ token }) => ({
        method: 'GET',
        url: `/service/auth/api/v1/eid/collect/${token}`,
        headers: serviceAuthHeaders(country)
      }),
      cancel: ({ token }) => ({
        method: 'DELETE',
        url: `/service/auth/api/v1/eid/cancel/${token}`,
        headers: serviceAuthHeaders(country)
      }),
      secret: ({ token }) => ({
        method: 'GET',
        url: `/service/auth/api/v1/eid/secret/${token}`,
        headers: serviceAuthHeaders(country)
      })
    },
    oauth: {
      create: ({ token }) => ({
        method: 'GET',
        url: `/service/auth/api/v1/oauth/create/${token}`,
        headers: serviceAuthHeaders(country),
        withCredentials: true
      }),
      refresh: () => ({
        method: 'GET',
        url: '/service/auth/api/v1/oauth/refresh',
        headers: serviceAuthHeaders(country),
        withCredentials: true
      }),
      reset: () => ({
        method: 'DELETE',
        url: '/service/auth/api/v1/oauth/reset',
        headers: serviceAuthHeaders(country),
        withCredentials: true
      })
    }
  }),
  gateway: {
    customer: {
      get: () => ({
        method: 'GET',
        url: '/service/common-gateway/api/v1/customer/uuid',
        withCredentials: true
      }),
      update: (customerId, email, phone) => ({
        method: 'PUT',
        url: `/service/common-gateway/api/v1/customer/${customerId}`,
        data: { email, phone },
        withCredentials: true
      })
    },
    trackingEvents: {
      pageView: (url) => ({
        method: 'POST',
        url: '/service/common-gateway/api/v1/tracking-events/page-view',
        data: { url },
        withCredentials: true
      })
    }
  },
  elskling: {
    helpers: {
      sendSalesforceEmail: ({
        country,
        brand,
        reference,
        to,
        messageName,
        channel,
        context
      }) => ({
        method: 'POST',
        url: '/service/electricity/helpers/sendSalesforceEmail',
        data: {
          source: {
            country,
            brand,
            vertical: 'Electricity',
            reference
          },
          recipient: {
            to
          },
          messageName,
          channel,
          context
        }
      }),
      getAllPriceWatches: (country, accessUuid) => ({
        method: 'GET',
        url: `/service/electricity/helpers/${country}/getAllPriceWatches/${accessUuid}`,
        withCredentials: true
      }),
      deletePriceWatch: (priceWatchId) => ({
        method: 'DELETE',
        url: `/service/electricity/helpers/deletePriceWatch/${priceWatchId}`,
        withCredentials: true
      })
    }
  },
  internal: {
    metric: (metric) => ({
      method: 'POST',
      url: `/${internalPrefix}/metric/${metric}`,
      withCredentials: true
    }),
    fragment: (url, ssi, ssr) => ({
      method: 'POST',
      url: `/${internalPrefix}/fragment`,
      data: {
        url,
        ssi,
        ssr
      },
      withCredentials: true
    })
  }
}
