import landing from './landing'
import login from './login'
import application from './application'
import myProperties from './my-properties'
import priceWatch from './price-watch'

export default {
  landing,
  login,
  application,
  myProperties,
  priceWatch
}
