export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    country: 'se',
    screenWidth: 'mobile',
    screenWidthPx: null,
    userAttributes: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getCountry: (state) => state.country,
    getScreenWidth: (state) => state.screenWidth,
    getScreenWidthPx: (state) => state.screenWidthPx,
    getUserAttributes: (state) => state.userAttributes
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    setCountry (state, v) {
      state.country = v
    },
    setScreenWidth (state) {
      state.screenWidthPx = window.innerWidth

      if (window.innerWidth < 768) {
        state.screenWidth = 'mobile'
      } else if (window.innerWidth < 992) {
        state.screenWidth = 'tablet'
      } else {
        state.screenWidth = 'desktop'
      }
    },
    setUserAttributes (state, v) {
      state.userAttributes = v
      if (v.isMobile) {
        state.screenWidth = 'mobile'
      } else if (v.isTablet) {
        state.screenWidth = 'tablet'
      } else {
        state.screenWidth = 'desktop'
      }
    }
  }
}
