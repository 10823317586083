<template>
  <div
    v-if="getUser.loggedIn"
    :class="{ 'expanded': expanded }"
  >
    <a @click="expanded = !expanded">
      <h4 class="user-name">
        {{ userName }}

        <zg-icon
          icon="chevron"
          :rotate="expanded ? '180' : '0'"
        />
      </h4>
    </a>

    <ul v-if="expanded">
      <li>
        <a href="/mina-sidor">
          <h4>{{ contentMyPages }}</h4>
        </a>
      </li>

      <li @click="logout">
        <h4 class="logout">
          {{ contentLogout }}

          <zg-icon icon="logout" />
        </h4>
      </li>
    </ul>
  </div>
</template>

<script>
  import { ZgIcon } from '@zc/components/ZgIcon'
  import { mapActions, mapGetters } from 'vuex'
  import { getProp } from '@ocp-zmarta/zmarta-cl'

  export default {
    name: 'MyPagesVerticalMenuButton',
    components: {
      ZgIcon
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      expanded: false
    }),
    computed: {
      ...mapGetters('user', ['getUser']),
      ...mapGetters('features', ['getFeatures']),
      staticContent () {
        return this.CONTENT
      },
      label () {
        return this.staticContent?.myPages?.login?.menu?.label
      },
      userName () {
        if (getProp(this.getUser, 'firstName') && getProp(this.getUser, 'lastName')) {
          return `${getProp(this.getUser, 'firstName')} ${getProp(this.getUser, 'lastName')}`
        } else {
          return ''
        }
      },
      contentLogout () {
        return getProp(this.content, 'microCopy', 'resources')?.find(r => r.contentId === '4ZbrrW15jkgclsGnnwrgdT')?.value
      },
      contentMyPages () {
        return getProp(this.content, 'microCopy', 'resources')?.find(r => r.contentId === '6GI2bi3DpKbp3zM90ZNXiP')?.value
      }
    },
    methods: {
      ...mapActions('messages', ['resetMessages']),
      showLogin () {
        this.resetMessages()
        this.EVENT_BUS.$emit('login:show')
      },
      goToMyPages () {
        if (!location.pathname.includes(this.MY_PAGES_URL)) {
          window.location.href = this.MY_PAGES_URL
        }
      },
      logout () {
        window.dispatchEvent(new window.Event('elskling-menu:logout'))
      },
      getProp
    }
  }
</script>

<style lang="scss" scoped>
  a {
    display: grid;
    height: rhythm(7);
    align-content: center;
    border-bottom: none;
  }

  h4 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding: rhythm("small");
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding: rhythm("small");
    border-bottom: 1px solid rgb(0 0 0 / 20%);
  }

  .expanded {
    background-color: color(beige);
  }

  .user-name {
    border-bottom: 1px solid rgb(0 0 0 / 20%);
  }

  .logout {
    justify-content: flex-start;
    align-items: center;
    column-gap: rhythm("xsmall");
  }

</style>
