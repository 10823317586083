import DOMPurify from 'isomorphic-dompurify'
import { isEmpty, getCookie } from '@ocp-zmarta/zmarta-cl'

import packageJson from '../../package.json'

/**
 * Checks that the variable isn't empty.
 * @param {*} x
 * @returns {boolean}
 */
export function isNotEmpty (x) {
  return !isEmpty(x)
}

/**
 * @param {string} word
 * @returns {string}
 */
export function capitalizeFirstLetter (word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * @returns {string}
 */
export function stateId (randomString) {
  const state = `__${packageJson.id}_${randomString}_STATE__`
  return state.replace(/-/g, '_').toUpperCase()
}

export function domPurify (string) {
  return DOMPurify.sanitize(string, {
    USE_PROFILES: {
      html: true
    }
  })
}

/**
 * @returns {object}
 */
export function replaceRichTextVariables (content, variables) {
  return content.map(contentPart => {
    const value = contentPart.value.replace(/{{\s*(\w+)\s*}}/g, (_, p1) => variables[p1])
    return {
      ...contentPart,
      value
    }
  })
}

/**
 * @description Checks if client is a user, based on the access_scope cookie
 * @returns {boolean}
 */
export function isUser () {
  return getCookie('access_scope') === 'user'
}
