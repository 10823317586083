export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    route: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getRoute: state => state.route,
    getQuery: state => state.route && state.route.query,
    getDomain: state => state.route && state.route.domain,
    getReferer: state => state.route && state.route.referer
  }
}
