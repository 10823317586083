import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    isMobile: true,
    isTablet: false,
    isDesktop: false
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getMisc: state => state,
    getIsMobile: state => state.isMobile,
    getIsTablet: state => state.isTablet,
    getIsDesktop: state => state.isDesktop
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setMisc ({ commit }, payload) {
      commit('mutateMisc', payload)
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateMisc (state, payload) {
      if (hasProp(payload, 'isMobile')) { Vue.set(state, 'isMobile', payload.isMobile) }
      if (hasProp(payload, 'isTablet')) { Vue.set(state, 'isTablet', payload.isTablet) }
      if (hasProp(payload, 'isDesktop')) { Vue.set(state, 'isDesktop', payload.isDesktop) }
    }
  }
}
