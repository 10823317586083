import { usableValue, validateField, getProp, hasProp, size, triggerEvent, isArray } from '@ocp-zmarta/zmarta-cl'
import { isNotEmpty } from '@utils'
import Vue from 'vue'

export default (country) => {
  const state = require(`./${country || 'se'}`).default

  return {
    namespaced: true,

    // ? ******************************************************************************************
    // ?  State
    // ? ******************************************************************************************

    state: {
      visibleFieldsAreValid: false,
      ...state
    },

    // ? ******************************************************************************************
    // ?  Getters
    // ? ******************************************************************************************

    getters: {
      getForm: state => state
    },

    // ? ******************************************************************************************
    // ?  Actions
    // ? ******************************************************************************************

    actions: {
      setField ({ commit }, payload) {
        commit('mutateForm', payload)
      },

      // * **************************************************************************************
      // * **************************************************************************************

      onBlur ({ state, dispatch }, { group, field, value }) {
        dispatch('validateField', {
          group,
          field,
          value,
          touched: true
        })
          .then(() => {
            const blurAction = getProp(state, group, field, 'blurAction')

            if (blurAction) {
              dispatch(`${blurAction}`, {
                group,
                field
              }, { root: true })
                .catch(() => {
                })
            }
          })
          .catch(() => {
          })
      },

      // * **************************************************************************************
      // * **************************************************************************************

      onInput ({ dispatch }, { group, field, value }) {
        dispatch('validateField', {
          group,
          field,
          value,
          touched: false
        })
      },

      // * **************************************************************************************
      // * **************************************************************************************

      async validateFields ({ state, commit, dispatch }, { showInvalidFields = true, country = 'se' } = {}) {
        let invalidFieldCounter = 0
        let firstInvalidElement = null
        const fields = state

        // Loops through all the form groups
        for await (const groupKey of Object.keys(fields)) {
          if (Object.hasOwnProperty.call(fields, groupKey)) {
            const group = fields[groupKey]

            // Loops through all the fields in the field group
            for await (const fieldKey of Object.keys(group)) {
              if (Object.hasOwnProperty.call(group, fieldKey)) {
                let validField = false
                const field = group[fieldKey]
                let element = null

                if (
                  getProp(field, 'type') === 'radio' ||
                  getProp(field, 'type') === 'radio-multiple' ||
                  getProp(field, 'type') === 'date'
                ) {
                  element = document.querySelector(`[name*=${groupKey}-${fieldKey}]`)
                } else {
                  element = document.querySelector(`[name=${groupKey}-${fieldKey}]`)
                }

                if (element) {
                  validField = await dispatch('validateField', {
                    country,
                    group: groupKey,
                    field: fieldKey,
                    value: field.value,
                    touched: showInvalidFields ? true : getProp(field, 'touched')
                  })

                  if (!validField) {
                    invalidFieldCounter++

                    if (!firstInvalidElement) {
                      firstInvalidElement = element
                    }
                  }
                }
              }
            }
          }
        }

        const validFields = (invalidFieldCounter === 0)
        commit('updateValidFields', validFields)
        if (!validFields && showInvalidFields) {
          firstInvalidElement.focus()
        }

        return validFields
      },

      // * **************************************************************************************
      // * **************************************************************************************

      async validateField ({ state, commit }, { country, group, field, value, touched }) {
        if (!group || !field) {
          return false
        }

        if (!getProp(state, group, field)) {
          return true
        }

        const valid = validateField({
          country,
          state,
          group,
          field,
          value,
          validation: getProp(state, group, field, 'validation') || undefined
        })

        commit('mutateForm', {
          group,
          field,
          value,
          touched,
          valid
        })

        return valid
      },

      // * **************************************************************************************
      // * **************************************************************************************

      filterField ({ state }, { group, field, value }) {
        const validFieldTypes = ['text', 'tel', 'number', 'email']
        const el = document.querySelector(`input[name="${group}-${field}"]:not(:focus)`)

        if (el && validFieldTypes.indexOf(el.getAttribute('type')) >= 0) {
          const filters = getProp(state, group, field, 'filters')

          function importFilterFunction (filter) {
            return require('@ocp-zmarta/zmarta-cl')[filter] || ''
          }

          if (size(value) && isArray(filters) && isNotEmpty(filters)) {
            for (const filter of filters) {
              const filterFunction = importFilterFunction(filter)
              value = filterFunction(value, country)
            }
          }

          return value
        }
      },

      // * **************************************************************************************
      // * **************************************************************************************

      async resetField ({ dispatch }, { group, field }) {
        await dispatch('setField', {
          group,
          field,
          value: undefined,
          touched: false,
          valid: false
        })
          .then(() => {
            const element = document.querySelector(`#form-input-${group}-${field}`)
            if (element) triggerEvent(element, 'reset')
          })
      },

      // * **************************************************************************************
      // * **************************************************************************************

      blurField (_, { group, field }) {
        if (!group || !field) {
          return
        }

        const query = `[name=${group}-${field}]`
        const element = document.querySelector(query)

        if (element) triggerEvent(element, 'blur')
      },

      // * **************************************************************************************
      // * **************************************************************************************

      focusField (_, { group, field }) {
        if (!group || !field) {
          return
        }

        const query = `[name=${group}-${field}]:not(:focus)`
        const element = document.querySelector(query)

        if (element) element.focus()
      },

      // * **************************************************************************************
      // * **************************************************************************************

      blurAllFields () {
        let inputs = document.querySelectorAll('input:not(:focus):not([type="checkbox"]):not([type="radio"])')
        inputs = Array.prototype.slice.call(inputs).map(item => item.getAttribute('id'))

        if (!size(inputs)) {
          return
        }

        inputs.forEach(item => {
          triggerEvent(document.getElementById(item) || null, 'blur')
        })
      },

      // * **************************************************************************************
      // * **************************************************************************************

      ssnBlur ({ dispatch }) {
        dispatch('validateFields', false)
          .catch(() => {
          })
      },

      // * ************************************************************************************************************
      // * ************************************************************************************************************

      setLeadFields ({ commit, rootGetters }, { fields }) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          if (!fields) {
            reject(new Error('missing fields'))
          }

          const currentForm = rootGetters['form/getForm']

          // Loops through all the form groups
          for (const groupKey of Object.keys(fields)) {
            if (Object.hasOwnProperty.call(fields, groupKey)) {
              const group = fields[groupKey]

              if (!group) {
                break
              }

              // Loops through all the fields in the field group
              for (const fieldKey of Object.keys(group)) {
                if (
                  hasProp(group, fieldKey) &&
                  usableValue(group[fieldKey]) &&
                  !(
                    getProp(currentForm, groupKey, fieldKey, 'touched') &&
                    getProp(currentForm, groupKey, fieldKey, 'valid')
                  )
                ) {
                  await commit('mutateForm', {
                    group: groupKey,
                    field: fieldKey,
                    value: group[fieldKey],
                    touched: true,
                    valid: true
                  })
                }
              }
            }
          }

          resolve()
        })
      }
    },

    // ? ******************************************************************************************
    // ?  Mutations
    // ? ******************************************************************************************

    mutations: {
      mutateForm (state, payload) {
        const currentField = getProp(state, payload.group, payload.field)
        if (!currentField) {
          return
        }

        // Don't mutate anything if the payload values are the same as the state values
        if (
          payload.value === getProp(state, payload.group, payload.field, 'value') &&
          payload.touched === getProp(state, payload.group, payload.field, 'touched') &&
          payload.valid === getProp(state, payload.group, payload.field, 'valid') &&
          payload.disabled === getProp(state, payload.group, payload.field, 'disabled')
        ) {
          return
        }

        if (hasProp(payload, 'value')) {
          // Convert string boolean to boolean
          if (payload.value === 'true') {
            payload.value = true
          }

          // Convert string boolean to boolean
          if (payload.value === 'false') {
            payload.value = false
          }

          // Convert unchecked checkboxes to false. They are set to undefined if not checked.
          if (getProp(state, payload.group, payload.field, 'type') === 'checkbox' && payload.value === undefined) {
            payload.value = false
          }

          // Convert string integer to integer
          if (['radio', 'radio-multiple'].indexOf(getProp(state, payload.group, payload.field, 'type')) !== -1 &&
            /^\d+$/.test(payload.value) &&
            payload.value.toString().charAt(0) !== '0'
          ) {
            payload.value = parseInt(payload.value, 10) || undefined
          }

          Vue.set(state[payload.group][payload.field], 'value', payload.value)
        }

        if (hasProp(payload, 'touched')) { Vue.set(state[payload.group][payload.field], 'touched', payload.touched) }
        if (hasProp(payload, 'valid')) { Vue.set(state[payload.group][payload.field], 'valid', payload.valid) }
        if (hasProp(payload, 'disabled')) { Vue.set(state[payload.group][payload.field], 'disabled', payload.disabled) }
      },

      updateValidFields (state, validFields) {
        Vue.set(state, 'visibleFieldsAreValid', validFields)
      }
    }
  }
}
