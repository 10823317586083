import { getProp } from '@ocp-zmarta/zmarta-cl'
import { getErrorName } from '../../utils/errors'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    successMessage: null,
    statusMessage: null,
    modalMessage: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getSuccessMessage: state => state.successMessage,
    getStatusMessage: state => state.statusMessage,
    getModalMessage: state => state.modalMessage
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setSuccessMessage ({ commit, rootGetters }, { group, name }) {
      const successMessage =
      getProp(this._vm.CONTENT, 'successMessages', group, name) ||
      getProp(this._vm.CONTENT, 'successMessages', group, 'default')
      commit('mutateMessages', { successMessage })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    setStatusMessage ({ commit, rootGetters }, { group, name }) {
      const statusMessage =
      getProp(this._vm.CONTENT, 'statusMessages', group, name) ||
      getProp(this._vm.CONTENT, 'statusMessages', group, 'default')
      commit('mutateMessages', { statusMessage })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    setModalMessage ({ commit, dispatch, rootGetters }, { group, name, show }) {
      const modalMessage =
      getProp(this._vm.CONTENT, 'modalMessages', group, getErrorName(name)) ||
      getProp(this._vm.CONTENT, 'modalMessages', group, 'default')

      commit('mutateMessages', { modalMessage })

      if (show) {
        dispatch('modal/showModal', {}, { root: true })
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    resetMessages ({ commit }) {
      commit('mutateMessages', {
        successMessage: null,
        statusMessage: null,
        modalMessage: null
      })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateMessages (state, payload) {
      Vue.set(state, 'successMessage', payload.successMessage || null)
      Vue.set(state, 'statusMessage', payload.statusMessage || null)
      Vue.set(state, 'modalMessage', payload.modalMessage || null)
    }
  }
}
