import Vue from 'vue'
import { createApp } from './app'
import { initiateSentry } from './utils/sentry'
import packageJson from '../package.json'
import VueAppend from 'vue-append'

Vue.use(VueAppend)

if (window.renderedFragments === undefined) {
  window.renderedFragments = []
}

let stateIdToRender
const stateIdsForComponent = Object.keys(window).filter((prop) => prop.includes(`__${packageJson.id}_`.replace(/-/g, '_').toUpperCase()))

for (const stateId of stateIdsForComponent) {
  if (!window.renderedFragments.includes(stateId)) {
    window.renderedFragments.push(stateId)
    stateIdToRender = stateId
    break
  }
}

const state = window[stateIdToRender]
const route = state?.router?.route
const sentryInfo = state?.sentryInfo
const isFragment = state?.isFragment

const { app, store } = createApp(route, state.utils.country)

// We initialize the store state with the data injected from the server
if (state) store.replaceState(state)

initiateSentry({ Vue, sentryInfo, isFragment })

app.$mount(`#${Vue.prototype.PROJECT_NAME}_${route?.component}`, true)
