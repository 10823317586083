export default {
  products: {
    microgeneration: {
      href: '/salj-el'
    },
    business: {
      href: '/foretag'
    },
    store: {
      // href: '/butik' // set in component since it's different urls for business and consumer
    },
    faq: {
      href: '/fragor-och-svar'
    }
  }
}
