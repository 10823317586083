import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export function createStore (route, country) {
  modules(country).router.state.route = route

  return new Vuex.Store({
    strict: process.env.LOCAL,
    modules: modules(country)
  })
}
