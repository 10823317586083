/* istanbul ignore file */

import axios from 'axios'

class CustomError extends Error {
  constructor (error) {
    super()
    this.name = error && error.name
    this.message = error && error.message
    this.errorCode = error && error.errorCode
    this.errorDetails = error && error.errorDetails
  }
}

export async function request ({ method, url, data, withCredentials, headers }) {
  return axios({
    method,
    url,
    data,
    withCredentials,
    headers
  })
    .then((res) => {
      return res && res.data
    })
    .catch(async e => {
      const error = e && e.response && e.response.data
      throw new CustomError(error)
    })
}
