/**
 * @param Vue
 * @param sentryInfo
 * @param isFragment
 * @returns {Promise<*|null>}
 */
export async function initiateSentry ({ Vue, sentryInfo, isFragment }) {
  const Sentry = await import('@sentry/vue')

  if (isFragment) {
    Vue.mixin(Sentry.createTracingMixins({ trackComponents: false, timeout: 500, hooks: [] }))
    Sentry.attachErrorHandler(Vue, { attachProps: true, logErrors: true, trackComponents: false, timeout: 500, hooks: [] })
  } else {
    const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, args => {
      const event = args.getEvent()
      if (typeof event?.tags?.appName === 'string') return window.__VITE_SENTRY_ROUTES__?.[event.tags.appName] ?? []
      else return []
    })

    Sentry.init({
      Vue,
      dsn: sentryInfo.dsn,
      release: sentryInfo.release,
      environment: sentryInfo.environment,
      transport,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration({ maskAllText: false })
      ],
      logErrors: true,
      tracesSampleRate: sentryInfo.sampleRate ?? 0.1,
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: sentryInfo.sampleRate ?? 0.1,
      tracePropagationTargets: [
        'localhost',
        /https?:\/\/([^/:]+\.)?zmarta\.(se|no|fi|org)(:\d+)?/,
        /https?:\/\/([^/:]+\.)?centum\.no(:\d+)?/,
        /https?:\/\/([^/:]+\.)?freedomfinance\.se(:\d+)?/,
        /https?:\/\/([^/:]+\.)?freedomrahoitus\.fi(:\d+)?/,
        /https?:\/\/([^/:]+\.)?elskling\.(se|no)(:\d+)?/
      ],
      beforeSend (event, hint) {
        const consents = window?.getCookieConsent?.()

        if (
          sentryInfo?.environment === 'development' ||
          window.location.host.startsWith('test') ||
          window.location.hostname === 'localhost'
        ) {
          return event
        }

        if (consents?.statistics) return event

        return null
      },
      beforeSendTransaction (event, hint) {
        const consents = window.getCookieConsent?.()

        if (
          sentryInfo.environment === 'development' ||
          window.location.host.startsWith('test') ||
          window.location.hostname === 'localhost'
        ) {
          return event
        }

        if (consents?.statistics) return event

        return null
      }
    })
  }
}
