import { request } from '../utils/request'
import endpoints from './endpoints'

export default {
  get: (url) => request(endpoints.get(url)),
  auth: (country) => ({
    bank: {
      list: () => request(endpoints.auth(country).bank.list())
    },
    eid: {
      initiate: ({ ssn, signMessage, bankId, isMobileEid }) => request(endpoints.auth(country).eid.initiate({ ssn, signMessage, bankId, isMobileEid })),
      collect: ({ token }) => request(endpoints.auth(country).eid.collect({ token })),
      cancel: ({ token }) => request(endpoints.auth(country).eid.cancel({ token })),
      secret: ({ token }) => request(endpoints.auth(country).eid.secret({ token }))
    },
    oauth: {
      create: ({ token }) => request(endpoints.auth(country).oauth.create({ token })),
      refresh: () => request(endpoints.auth(country).oauth.refresh()),
      reset: () => request(endpoints.auth(country).oauth.reset())
    }
  }),
  gateway: {
    customer: {
      get: () => request(endpoints.gateway.customer.get()),
      update: (customerId, email, phone) => request(endpoints.gateway.customer.update(customerId, email, phone))
    },
    trackingEvents: {
      pageView: (url) => request(endpoints.gateway.trackingEvents.pageView(url))
    }
  },
  elskling: {
    helpers: {
      sendSalesforceEmail: ({
        country,
        brand,
        reference,
        to,
        messageName,
        channel,
        context
      }) => request(endpoints.elskling.helpers.sendSalesforceEmail({
        country,
        brand,
        reference,
        to,
        messageName,
        channel,
        context
      })),
      getAllPriceWatches: (country, accessUuid) => request(endpoints.elskling.helpers.getAllPriceWatches(country, accessUuid)),
      deletePriceWatch: (priceWatchId) => request(endpoints.elskling.helpers.deletePriceWatch(priceWatchId))
    }
  },
  internal: {
    metric: (metric) => request(endpoints.internal.metric(metric)),
    fragment: (url, ssi, ssr) => request(endpoints.internal.fragment(url, ssi, ssr))
  }
}
