export default {
  loading: 'Tar bort prisbevakning',
  title: {
    successful: 'Prisbevakning borttagen',
    error: 'Något gick fel, vänligen prova igen senare'
  },
  message: {
    successful: 'Din prisbevakning har nu blivit borttagen',
    error: 'Prisbevakningen kunde inte tas bort'
  }
}
