export default {
  productTypes: {
    FIXED: 'Fast pris',
    MOVING_HOURLY: 'Timpris',
    MOVING_MONTHLY: 'Rörligt pris',
    MIXED: 'Mixpris'
  },
  detailLabels: {
    startDate: 'Startdatum',
    validityPeriod: 'Villkorstid',
    commitmentPeriod: 'Bindningstid',
    totalPrice: 'Öre/kWh'
  },
  monthSuffix: 'mån',
  receipt: {
    buttonText: 'Ladda ner kvitto'
  },
  statusLabels: {
    ONGOING: 'Pågående',
    PAUSED: 'Pausad',
    CANCELLED: 'Avbruten'
  },
  statusDetails: {
    INITIATED: 'Behandlas av Elskling.',
    REGISTERED: 'Behandlas av Elskling.',
    'REGISTERED-P': 'Uppgifter saknas - vi kommer att kontakta dig inom kort.',
    'REGISTERED-M': 'Uppgifter saknas - vänligen kontakta Elskling på 08-000 000 00 för att få hjälp med ditt byte.',
    APPROVED: 'Redo att skickas till elbolaget.',
    SUBMITTED: 'Väntar på svar från elbolaget.',
    CONFIRMED: 'Elbolaget har tagit emot din kundansökan.',
    RETURNED: 'Elavtalet behöver kompletteras - vi kommer att kontakta dig inom kort.',
    'RETURNED-P': 'Förmedling misslyckad. Hanteras av Elsklings kundtjänst.',
    'RETURNED-M': 'Elavtalet behöver kompletteras - vänligen kontakta Elskling på 08-000 000 00 för att få hjälp med ditt byte.',
    'WAITING-F': 'Avtalet behöver signeras med Bank-ID.',
    'WAITING-Z': 'Behandlas av Elskling.',
    REPLACED: 'Ersatt med ett annat elavtal.',
    REGRETTED: 'Elavtalet har avbokats.',
    DENIED: 'Din kundansökan har blivit nekad av elbolaget.',
    'POA-F': 'Signering av avtalet misslyckades - vi kommer att kontakta dig inom kort.',
    PAID: 'Väntar på startdatum.',
    INVOICED: 'Väntar på startdatum.'
  }
}
