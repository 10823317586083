<template>
  <div class="page-meta" />
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getProp } from '@ocp-zmarta/zmarta-cl'

  export default {
    name: 'PageMeta',
    metaInfo () {
      const baseUrl = this.BASE_URL

      const robots = getProp(this.getRoute, 'meta', 'robots')
      const keywords = getProp(this.getRoute, 'meta', 'keywords')
      const description = getProp(this.getRoute, 'meta', 'description')
      const canonical = getProp(this.getRoute, 'meta', 'canonical') || `${baseUrl}`.replace(/\/$/, '')
      const useGtm = getProp(this.getRoute, 'meta', 'gtm') !== false && getProp(this.getRoute, 'meta', 'fragment') !== true
      const dnsPrefetch = [
        'https://www.google-analytics.com',
        'https://www.googletagmanager.com',
        'https://adservice.google.com',
        'https://8817008.fls.doubleclick.net'
      ]

      let scripts = []
      let noscripts = []
      let disableSanitizers = []

      if (useGtm) {
        const gtm = this.ZGA.init()

        scripts = gtm.vueMeta?.scripts || []
        noscripts = gtm.vueMeta?.noscripts || []
        disableSanitizers = gtm.vueMeta?.disableSanitizers || []
      }

      return {
        htmlAttrs: {
          lang: this.getCountry
        },
        ...(this.LOCAL || !this.isFragment
          ? {
            title: getProp(this.getRoute, 'meta', 'title') || ''
          }
          : {}),
        meta: [
          ...(this.LOCAL || !this.isFragment
            ? [
              { charset: 'utf-8' },
              { name: 'viewport', content: 'width=device-width, initial-scale=1' }
            ]
            : []),
          ...(robots && !this.isFragment
            ? [
              { name: 'robots', content: robots }
            ]
            : []),
          ...(keywords && !this.isFragment
            ? [
              { name: 'keywords', content: keywords }
            ]
            : []),
          ...(description && !this.isFragment
            ? [
              { name: 'description', content: description }
            ]
            : [])
        ],
        link: [
          ...(!this.isFragment
            ? [
              { rel: 'canonical', href: canonical },
              ...(dnsPrefetch.map(link => ({
                rel: 'dns-prefetch', href: link
              })))
            ]
            : [])
        ],
        script: [
          ...scripts
        ],
        noscript: [
          ...noscripts
        ],
        __dangerouslyDisableSanitizersByTagID: {
          ...disableSanitizers
        }
      }
    },
    computed: {
      ...mapGetters({
        getRoute: 'router/getRoute',
        getCountry: 'utils/getCountry'
      }),
      isFragment () {
        return getProp(this.getRoute, 'meta', 'fragment') === true
      }
    }
  }
</script>
