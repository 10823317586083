<template>
  <login-modal
    :visible="visible"
    :title="getProp(content, 'login', 'title')"
    :notice="notice"
    :message="message"
    :error="error"
    :show-close="!loading"
    @close="close"
    @submit="initAuth"
  >
    <template
      v-if="loading"
      #loading
    >
      <div
        v-if="getAuthentication.showSecret"
        class="qr-code"
      >
        <div class="code">
          <overlay-loader :loading="!secret" />

          <img
            :src="secret"
            alt=""
          >
        </div>

        <ol v-if="staticContent.myPages.login.steps">
          <li
            v-for="(step, index) in staticContent.myPages.login.steps"
            :key="index"
          >
            {{ step }}
          </li>
        </ol>

        <div
          v-if="staticContent.myPages.login.sameDevice"
          class="same-device"
        >
          <h5>
            {{ staticContent.myPages.login.sameDevice.title }}
          </h5>

          <p>
            {{ staticContent.myPages.login.sameDevice.link }}
          </p>
        </div>
      </div>

      <login-loader
        v-else
        :status="status"
      />
    </template>

    <template #buttons>
      <div
        v-if="loading"
        key="loading-btns"
      >
        <template v-if="showOpenEidApp">
          <zg-button
            :icons="{ right: 'bank-id-se' }"
            :disabled="!autostarttoken"
            :loading="!autostarttoken"
            class="login-with-eid"
            outline
            full-width
            type="button"
            @click="openBankId"
          >
            {{ staticContent.myPages.login.buttons.openBankid }}
          </zg-button>
        </template>

        <zg-button
          modifier="attention"
          type="button"
          @click="cancelEidSe()"
        >
          {{ content.buttons.cancelEid }}
        </zg-button>
      </div>
    </template>
  </login-modal>
</template>

<script>
  import { getProp } from '@ocp-zmarta/zmarta-cl'
  import LoginLoader from '../LoginLoader.vue'
  import { ZgButton } from '@zc/components/ZgButton'
  import OverlayLoader from '../../misc/OverlayLoader.vue'
  import LoginModal from '../LoginModal.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LoginSe',
    components: {
      LoginLoader,
      ZgButton,
      OverlayLoader,
      LoginModal
    },
    data: () => ({
      loginType: 'eid',
      visible: false
    }),
    computed: {
      ...mapGetters('form', ['getForm']),
      ...mapGetters('features', ['getFeatures']),
      ...mapGetters('authentication', ['getAuthentication', 'getAuthenticationIsPendingStart']),
      ...mapGetters('messages', ['getStatusMessage', 'getModalMessage', 'getSuccessMessage']),
      staticContent () {
        return this.CONTENT
      },
      content () {
        return this.staticContent?.myPages?.login
      },
      validFields () {
        return getProp(this.getForm, 'visibleFieldsAreValid')
      },
      eidIsDown () {
        return this.getFeatures?.['eid-is-down']?.enabled
      },
      loading () {
        return this.getAuthentication?.loading
      },
      status () {
        return this.getStatusMessage ?? ''
      },
      message () {
        return this.getSuccessMessage ?? ''
      },
      notice () {
        return this.useEid && this.eidIsDown
          ? this.content.messages.eidIsDown ?? ''
          : ''
      },
      error () {
        return this.getModalMessage?.text ?? ''
      },
      secret () {
        return this.getAuthentication?.secret
      },
      autostarttoken () {
        return this.getAuthentication?.autoStartToken
      },
      showOpenEidApp () {
        if (this.getAuthentication.showSecret && this.getIsDesktop) {
          return true
        } else if (this.getAuthenticationIsPendingStart) {
          return true
        }

        return false
      }
    },
    watch: {
      visible (val) {
        if (val) {
          this.initAuth()
        }
      }
    },
    async mounted () {
      this.EVENT_BUS.$on('login:show', this.show)
      this.EVENT_BUS.$on('login:close', this.close)

      window.addEventListener('elskling-menu:login', this.show)

      this.resetAuthentication()
    },
    destroyed () {
      this.EVENT_BUS.$off('login:show', this.show)
      this.EVENT_BUS.$off('login:close', this.close)

      window.removeEventListener('elskling-menu:login', this.show)
    },
    methods: {
      ...mapActions('form', ['validateFields']),
      ...mapActions('authentication', ['resetAuthentication', 'cancelEidSe', 'resetLoading']),
      ...mapActions('messages', ['resetMessages']),
      getProp,
      close () {
        if (this.loading) {
          return
        }
        this.EVENT_BUS.$emit('authentication:kill')
        this.cancelEidSe()

        this.visible = false
      },
      show () {
        this.resetMessages()
        this.resetLoading()

        this.visible = true
      },
      openBankId () {
        this.EVENT_BUS.$emit('authentication:bankid:open')
      },
      async initAuth () {
        await this.validateFields()

        this.resetAuthentication()
        this.resetMessages()

        this.EVENT_BUS.$emit('authentication:initiate', {
          loginType: this.loginType
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .login-with-eid {
  padding: rhythm(2) 0 rhythm(2) rhythm(3);

  span {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.button {
  &--disabled {
    opacity: 0.5;
  }
}

.qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: rem(16px);

  .code {
    position: relative;
    width: rem(180px);
    height: rem(180px);
    background-color: color(white);
    border-radius: radius(box);
    overflow: hidden;
  }

  ol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: rem(8px);
  }
}
</style>
