const countrySpecific = (country) => require(`./${country}`).default

export const constants = (country) => {
  return {
    IMG_SRC: `/frontend-el-pages/images/${country}`,
    GA: {
      PLATFORM: 'zmarta',
      PRODUCT_FAMILY: 'utilities',
      PRODUCT: {
        consumer: 'consumer-electricity',
        business: 'corporate-electricity'
      },
      FORM_STEP: 'zip-code',
      BUTTON_LABEL_COMPARE: 'compare'
    },
    APPLICATION_STATUS: {
      ONGOING: ['INITIATED', 'REGISTERED', 'REGISTERED-P', 'APPROVED', 'SUBMITTED', 'RETURNED', 'RETURNED-P', 'WAITING-F', 'WAITING-Z'],
      PAUSED: ['REGISTERED-M', 'RETURNED-M'],
      HISTORIC: ['CONFIRMED', 'INVOICED', 'PAID'],
      CANCELLED: ['REGRETTED', 'REPLACED', 'DENIED', 'POA-F']
    },
    ...countrySpecific(country)
  }
}

export default countrySpecific
