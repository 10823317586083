<template>
  <div
    :class="['overlay-loader', {
      'overlay-loader--visible': loading,
      'overlay-loader--only-bg': onlyBg
    }]"
  >
    <zg-loader
      v-if="!onlyBg"
      :button="button"
    />
  </div>
</template>

<script>
  // FYI: the container in which this component is included in needs 'position: ***;' to be set

  import { ZgLoader } from '@zc/components/ZgLoader'

  export default {
    name: 'OverlayLoader',
    components: {
      ZgLoader
    },
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      button: {
        type: Boolean,
        required: false,
        default: false
      },
      onlyBg: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .overlay-loader {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(16px) 0;
    opacity: 0;
    visibility: hidden;
    background-color: color(white);
    transition: all 0.3s ease;
    z-index: 10;

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    &--only-bg {
      padding: 0;
    }
  }
</style>
