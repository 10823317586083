export default {
  LANG: 'nb-no',
  BASE_URL: 'https://www.zmarta.no',
  INPUT_LENGTH: {
    YEARLY_CONSUMPTION: {
      MAX: 6
    },
    ADDRESS: {
      MAX: 95
    },
    ZIP_CODE: {
      REQUIRED: 4
    },
    FACILITY_ID: {
      REQUIRED: 18
    }
  },
  FORM_PAGE: {
    zmarta: {
      consumer: '/strom/sammenlign/1'
    },
    elskling: {
      consumer: '/strom/sammenlign/1'
    }
  },
  AUTHENTICATION: {
    POLL_EID: false,
    REDIRECT_EID: true,
    AUTO_START_EID: false,
    VERIFY_EID: true
  },
  FORM_PATH_KEYWORD: 'sammenlign/',
  FORM_SIGNED_PATH_KEYWORD: 'sammenlign/4'
}
