import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {},

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getVariations: state => state
  },

  mutations: {
    setVariations (state, variations) {
      for (const key in state) {
        delete state[key]
      }
      for (const key in variations) {
        Vue.set(state, key, variations[key])
      }
    }
  }
}
