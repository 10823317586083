import requests from '@src/requests'
import { getProp, hasProp, likelyLoggedIn } from '@ocp-zmarta/zmarta-cl'
import { notAuthError } from '../../utils/errors'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    loggedIn: undefined,
    loading: false,
    id: null,
    ssn: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    phoneAlt: null,
    street: null,
    apartmentNumber: null,
    zipCode: null,
    city: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getUser: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async fetchUser ({ rootState, commit, dispatch }, type = null) {
      if (!likelyLoggedIn()) {
        await new Promise(resolve => setTimeout(resolve, 100)) // this timeout allows components to mount before the event below is fired
        window.dispatchEvent(new window.Event('elskling-menu:logout:not-authorized'))
        return false
      }
      try {
        const response = await requests.gateway.customer.get()
        if (!notAuthError(getProp(response, 'errorCode'))) {
          throw response
        }
        if (response.id) {
          type && Vue.prototype.ZGA.event.misc.loginAttempt({ method: `${type}` })
          window.sessionStorage.setItem('uid', response.id)

          commit('mutateUser', {
            loggedIn: true,
            ...response
          })
          window.dispatchEvent(new window.Event('elskling-menu:logged-in'))

          dispatch('messages/resetMessages', {}, { root: true })
          return true
        } else {
          type && Vue.prototype.ZGA.event.misc.loginAttempt({ success: false, method: `${type}` })
          commit('mutateUser', {
            loggedIn: false,
            ...response
          })
          window.dispatchEvent(new window.Event('elskling-menu:logged-out'))
          return false
        }
      } catch (e) {
        type && Vue.prototype.ZGA.event.misc.loginAttempt({ success: false, method: `${type}` })
        if (!notAuthError(e.errorCode)) {
          if (e.errorCode === 'JWT_EXPIRED' && !rootState.authentication.refreshAttempted) {
            const tokenRefreshed = await this.dispatch('authentication/refreshOauth')
            if (tokenRefreshed) {
              await this.dispatch('user/fetchUser')
              return
            }
          }
        }
        console.error(e)
        commit('mutateUser', { loggedIn: false })
        window.dispatchEvent(new window.Event('elskling-menu:logged-out'))
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async saveUser ({ commit, dispatch, rootGetters }) {
      const customerId = getProp(rootGetters['user/getUser'], 'id')
      const email = getProp(rootGetters['form/getForm'], 'profile', 'email', 'value')
      const phone = getProp(rootGetters['form/getForm'], 'profile', 'phone', 'value')

      if (!customerId || !email || !phone) {
        dispatch('messages/setModalMessage', {
          group: 'user'
        }, { root: true })
          .then(() => {
            commit('mutateLoading', false)
          })

        return true
      }

      dispatch('messages/setStatusMessage', {
        group: 'user',
        name: 'saving'
      }, { root: true })
        .then(() => {
          commit('mutateLoading', true)
        })

      try {
        const user = await requests.gateway.customer.update(customerId, email, `${phone}`)

        commit('mutateUser', { ...user })
        dispatch('messages/setSuccessMessage', {
          group: 'user',
          name: 'saved'
        }, { root: true })
          .then(() => {
            commit('mutateLoading', false)
          })
      } catch ({ errorCode }) {
        console.log('errorCode', errorCode)

        dispatch('messages/setModalMessage', {
          group: 'user',
          name: errorCode,
          show: true
        }, { root: true })

        commit('mutateLoading', false)
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    resetUser ({ commit }) {
      commit('mutateUser', {
        loggedIn: false,
        loading: false,
        id: null,
        ssn: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneAlt: null,
        street: null,
        apartmentNumber: null,
        zipCode: null,
        city: null
      })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateUser (state, payload) {
      if (hasProp(payload, 'loggedIn')) {
        Vue.set(state, 'loggedIn', payload.loggedIn)
      }
      if (hasProp(payload, 'ssn')) {
        Vue.set(state, 'ssn', payload.ssn)
      }
      if (hasProp(payload, 'id')) {
        Vue.set(state, 'id', payload.id)
      }
      if (hasProp(payload, 'firstName')) {
        Vue.set(state, 'firstName', payload.firstName)
      }
      if (hasProp(payload, 'lastName')) {
        Vue.set(state, 'lastName', payload.lastName)
      }
      if (hasProp(payload, 'email')) {
        Vue.set(state, 'email', payload.email)
      }
      if (hasProp(payload, 'phone')) {
        Vue.set(state, 'phone', payload.phone)
      }
      if (hasProp(payload, 'phoneAlt')) {
        Vue.set(state, 'phoneAlt', payload.phoneAlt)
      }
      if (hasProp(payload, 'street')) {
        Vue.set(state, 'street', payload.street)
      }
      if (hasProp(payload, 'apartmentNumber')) {
        Vue.set(state, 'apartmentNumber', payload.apartmentNumber)
      }
      if (hasProp(payload, 'zipCode')) {
        Vue.set(state, 'zipCode', payload.zipCode)
      }
      if (hasProp(payload, 'city')) {
        Vue.set(state, 'city', payload.city)
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateLoading (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  }
}
