import Vue from 'vue'
import Meta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import ZmartaAnalytics from '@ocp-zmarta/zmarta-analytics'
import ZmartaExperiments from '@ocp-zmarta/zmarta-experiments'
import { sentryErrorHandler } from './utils/sentry'
import App from './App.vue'
import content from './content'
import { constants } from './constants'
import { createStore } from './store'

// vue config
Vue.config.devtools = (process.env.NODE_ENV !== 'production')
Vue.config.debug = (process.env.NODE_ENV !== 'production')

// global variables - less string literals in Vue components
Vue.prototype.PROJECT_NAME = process.env.PROJECT_NAME
Vue.prototype.BUILD_NAME = process.env.BUILD_NAME
Vue.prototype.FRAGMENTS = process.env.fragments
Vue.prototype.PRODUCTION = (process.env.NODE_ENV === 'production')
Vue.prototype.DEVELOPMENT = (process.env.NODE_ENV === 'development')
Vue.prototype.LOCAL = (process.env.LOCAL === true || process.env.LOCAL === 'true')
Vue.prototype.SERVICES = process.env.services
Vue.prototype.ELECTRICITY_FORM = process.env.electricityForm
Vue.prototype.CLOUDFRONT = process.env.cloudfront

Vue.use(Meta)
Vue.use(VueScrollTo)

export function createApp (route = null, country = 'se') {
  for (const property in constants(country)) {
    Vue.prototype[property] = constants(country)[property]
  }

  const brand = route?.domain || 'elskling'
  const product = route?.query?.product || 'consumer'

  const zmartaExperiments = new ZmartaExperiments({
    brand,
    country,
    environment: Vue.prototype.PRODUCTION
      ? 'production'
      : 'development'
  })

  const zmartaAnalytics = new ZmartaAnalytics({
    country,
    brand,
    productFamily: Vue.prototype.GA.PRODUCT_FAMILY,
    product: Vue.prototype.GA.PRODUCT[product],
    silence: Vue.prototype.PRODUCTION,
    environment: Vue.prototype.PRODUCTION
      ? 'production'
      : 'development'
  })

  const store = createStore(route, country)
  store.EXPERIMENTS = zmartaExperiments
  Vue.prototype.CONTENT = content(country)
  Vue.prototype.COUNTRY = country
  Vue.prototype.COUNTRY = country
  Vue.prototype.SWEDEN = (country === 'se')
  Vue.prototype.NORWAY = (country === 'no')
  Vue.prototype.BRAND = brand
  Vue.prototype.EVENT_BUS = new Vue()
  Vue.prototype.EXPERIMENTS = zmartaExperiments
  Vue.prototype.ZGA = zmartaAnalytics
  Vue.prototype.TESTCAFE = route?.query?.testcafe !== undefined

  const app = new Vue({
    store,
    errorCaptured (err, vm, info) {
      sentryErrorHandler(err, vm, info, 'frontend-el-pages')
    },
    render: h => h(App)
  })

  return { app, store }
}
