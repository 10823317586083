<template>
  <div class="fragment">
    <div
      v-if="preRender"
      :data-ssi="ssi"
      :data-ssr="ssr"
      :data-url="url"
    />

    <lazy-hydrate
      v-else
      when-idle
    >
      <div>
        <zg-loader
          v-if="!html"
          key="loader"
          linear
        />

        <div
          v-else
          key="html"
          v-append="html"
        />
      </div>
    </lazy-hydrate>
  </div>
</template>

<script>
  import { ZgLoader } from '@zc/components/ZgLoader'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions } from 'vuex'
  import { getFragment } from '@ocp-zmarta/zmarta-cl'

  export default {
    name: 'Fragment',
    components: {
      LazyHydrate,
      ZgLoader
    },
    props: {
      name: {
        type: String,
        required: true
      },
      preRender: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      html: null
    }),
    computed: {
      fragment () {
        return getFragment({ name: this.name, brand: this.BRAND, country: this.COUNTRY, fragments: this.FRAGMENTS })
      },
      url () {
        return this.fragment && this.fragment.url
      },
      ssi () {
        return this.fragment.type === 'ssi'
      },
      ssr () {
        return this.fragment.type === 'ssr'
      }
    },
    mounted () {
      this.setHtml()
    },
    methods: {
      ...mapActions('fragments', ['getFragmentHtml']),
      async setHtml () {
        if (this.preRender) return

        this.html = await this.getFragmentHtml({
          url: this.url,
          ssi: this.ssi,
          ssr: this.ssr
        })
      }
    }
  }
</script>
