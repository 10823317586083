export default {
  products: {
    microgeneration: {
      scrollTo: '1QRTOckFFM2lAFh3iaFyb0'
    },
    energySavingTips: {
      href: '/stromsparetips'
    },
    store: {
      href: '/butikk'
    },
    faq: {
      href: '/sporsmal_og_svar'
    }
  }
}
