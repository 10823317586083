import requests from '@src/requests'
import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    success: false,
    token: null,
    autoStartToken: null,
    showSecret: false,
    secret: null,
    status: null,
    hintCode: null,
    loading: false,
    verifyEid: false,
    userCancel: false
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getAuthentication: state => state,
    getAuthenticationIsPendingStart: state => state.status === 'start' || (state.status === 'pending' && state.hintCode === 'outstandingTransaction')
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setAuthentication ({ commit }, payload) {
      commit('mutateAuth', payload)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEid ({ commit, dispatch }) {
      dispatch('messages/resetMessages', {}, { root: true })

      await commit('mutateAuth', {
        status: 'start',
        hintCode: 'start'
      })

      if (Vue.prototype.COUNTRY === 'se') {
        return await dispatch('initiateEidSe')
      } else if (Vue.prototype.COUNTRY === 'no') {
        return await dispatch('initiateEidNo')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEidSe ({ commit, dispatch, rootGetters }) {
      const eidProvider = 'bankid'

      if (rootGetters['misc/getIsDesktop']) {
        await commit('mutateShowSecret', true)
      }

      await dispatch('messages/setStatusMessage', {
        group: 'bankid',
        name: rootGetters['misc/getIsDesktop'] ? 'start' : 'startMobile'
      }, { root: true })

      await commit('mutateUserCancel', false)
      await commit('mutateLoading', true)

      try {
        const { orderRef, autoStartToken } = await requests.auth(Vue.prototype.COUNTRY).eid.initiate({ eidProvider })
        if (!orderRef || !autoStartToken) {
          Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

          await dispatch('messages/setModalMessage', {
            group: 'authentication'
          }, { root: true })
          commit('mutateLoading', false)
          await dispatch('resetAuthentication')

          return false
        }

        commit('mutateAuth', {
          token: orderRef,
          autoStartToken
        })

        return true
      } catch (e) {
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: e?.errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEidNo ({ commit, dispatch, rootGetters }) {
      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'loggingIn'
      }, { root: true })
      commit('mutateLoading', true)

      try {
        const { orderRef, url } = await requests.auth(Vue.prototype.COUNTRY).eid.initiate({ isMobileEid: true })

        if (!orderRef || !url) {
          Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

          await dispatch('messages/setModalMessage', {
            group: 'authentication'
          }, { root: true })
          commit('mutateLoading', false)
          await dispatch('resetAuthentication')

          return false
        }

        commit('mutateAuth', {
          token: orderRef,
          verifyEid: true
        })

        return url
      } catch (e) {
        console.error(e)
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: e?.errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEid ({ dispatch }) {
      if (Vue.prototype.SWEDEN) {
        return await dispatch('secretEidSe')
      } else if (Vue.prototype.NORWAY) {
        return await dispatch('secretEidNo')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEidSe ({ commit, state }) {
      const token = state.token || null

      if (!token) {
        return false
      }

      try {
        const { secret } = await requests.auth(Vue.prototype.COUNTRY).eid.secret({ token })
        await commit('mutateAuth', { secret })

        return true
      } catch (e) {
        console.log('authentication/secretEidSe', e)
        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEid ({ dispatch }) {
      if (Vue.prototype.COUNTRY === 'se') {
        return await dispatch('collectEidSe')
      } else if (Vue.prototype.COUNTRY === 'no') {
        return await dispatch('collectEidNo')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEidSe ({ commit, state, dispatch, rootGetters }) {
      const token = state.token || null
      const userCancel = state.userCancel

      if (!token) {
        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: userCancel ? 'userForceCancel' : null
        }, { root: true })
        commit('mutateLoading', false)
        commit('mutateUserCancel', false)
        await dispatch('resetAuthentication')

        return false
      }

      try {
        const { status, hintCode } = await requests.auth(Vue.prototype.COUNTRY).eid.collect({ token })

        return await dispatch('handleCollect', { status, hintCode })
      } catch (e) {
        console.error(e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: e?.errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEidNo ({ commit, state, dispatch }) {
      if (!state.verifyEid) return false

      Vue.prototype.EVENT_BUS.$emit('login:show')

      commit('mutateLoading', true)
      commit('mutateAuth', { verifyEid: false })

      if (state.success) {
        commit('mutateLoading', false)
        return false
      }

      const token = state.token

      if (!token) {
        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }

      try {
        const { status, hintCode } = await requests.auth(Vue.prototype.COUNTRY).eid.collect({ token })
        return await dispatch('handleCollect', { status, hintCode })
      } catch (e) {
        console.error(e)
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: e?.errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async handleCollect ({ commit, dispatch, rootGetters }, { status, hintCode }) {
      await commit('mutateAuth', {
        status,
        hintCode
      })

      if (status === 'complete') {
        return true
      } else if (status === 'failed') {
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: hintCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else if (status === 'no-customer') {
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: 'noCustomer'
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else if (!status) {
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false })

        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else {
        if (hintCode !== 'outstandingTransaction') {
          await commit('mutateShowSecret', false)
        }

        let name = hintCode
        let group = 'authentication'
        if (Vue.prototype.SWEDEN && !rootGetters['misc/getIsDesktop']) {
          name = `${name}Mobile`

          if (['userSign', 'started'].includes(hintCode)) {
            group = 'authentication'
            name = 'confirming'
          }
        }
        await dispatch('messages/setStatusMessage', {
          group,
          name
        }, { root: true })
        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEid ({ dispatch }) {
      Vue.prototype.EVENT_BUS.$emit('authentication:kill')

      if (Vue.prototype.COUNTRY === 'se') {
        return await dispatch('cancelEidSe')
      } else if (Vue.prototype.COUNTRY === 'no') {
        return await dispatch('cancelEidNo')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEidSe ({ commit, state, dispatch }) {
      const token = state.token || null

      if (!token) {
        commit('mutateLoading', false)

        return
      }

      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'cancelling'
      }, { root: true })
      commit('mutateLoading', true)

      try {
        await requests.auth(Vue.prototype.COUNTRY).eid.cancel({ token })

        commit('mutateLoading', false)
        commit('mutateUserCancel', true)
        await dispatch('resetAuthentication')
      } catch (e) {
        console.error(e)

        commit('mutateLoading', false)
        await dispatch('resetAuthentication')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEidNo ({ commit, dispatch }) {
      commit('mutateLoading', false)
      await dispatch('resetAuthentication')

      return false
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async createOauth ({ commit, state, dispatch, rootGetters }, type = 'eid') {
      const token = state.token || null

      if (!token) {
        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false, method: `${type}` })
        return false
      }

      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'confirming'
      }, { root: true })
      commit('mutateLoading', true)

      try {
        await requests.auth(Vue.prototype.COUNTRY).oauth.create({ token })

        commit('mutateAuth', {
          success: true,
          token: null,
          autoStartToken: null,
          loading: false,
          url: null
        })

        commit('mutateLoading', false)
        commit('mutateRefreshAttempted', false)
        await dispatch('user/fetchUser', type, { root: true })

        Vue.prototype.EVENT_BUS.$emit('login:close')
        return true
      } catch (e) {
        console.error(e)
        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false, method: `${type}` })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: e?.errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async refreshOauth ({ commit }) {
      commit('mutateRefreshAttempted', true)
      try {
        await requests.auth(Vue.prototype.COUNTRY).oauth.refresh()
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async logout ({ commit, dispatch }) {
      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'loggingOut'
      }, { root: true })
      commit('mutateLoading', true)

      try {
        await requests.auth(Vue.prototype.COUNTRY).oauth.reset()

        await dispatch('resetAuthentication')
        await dispatch('autoLogout')

        window.dispatchEvent(new window.Event('elskling-menu:logged-out'))

        Vue.prototype.EVENT_BUS.$emit('logout:redirect')
      } catch ({ errorCode }) {
        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: errorCode
        }, { root: true })
        commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        Vue.prototype.EVENT_BUS.$emit('login:close')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async autoLogout ({ dispatch }) {
      try {
        await dispatch('user/resetUser', {}, { root: true })
        await dispatch('form/resetField', { group: 'login', field: 'ssn' }, { root: true })
      } catch (e) {
        console.error(e)
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    resetLoading ({ commit }) {
      commit('mutateLoading', false)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    resetAuthentication ({ commit }) {
      commit('mutateAuth', {
        success: false,
        type: null,
        token: null,
        autoStartToken: null,
        loading: false,
        url: null
      })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateAuth (state, payload) {
      if (hasProp(payload, 'success')) { Vue.set(state, 'success', payload.success) }
      if (hasProp(payload, 'token')) { Vue.set(state, 'token', payload.token) }
      if (hasProp(payload, 'autoStartToken')) { Vue.set(state, 'autoStartToken', payload.autoStartToken) }
      if (hasProp(payload, 'secret')) { Vue.set(state, 'secret', payload.secret) }
      if (hasProp(payload, 'loading')) { Vue.set(state, 'loading', payload.loading) }
      if (hasProp(payload, 'verifyEid')) { Vue.set(state, 'verifyEid', payload.verifyEid) }
      if (hasProp(payload, 'url')) { Vue.set(state, 'url', payload.url) }
      if (hasProp(payload, 'status')) { Vue.set(state, 'status', payload.status) }
      if (hasProp(payload, 'hintCode')) { Vue.set(state, 'hintCode', payload.hintCode) }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateUserCancel (state, cancel) {
      Vue.set(state, 'userCancel', cancel)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateLoading (state, loading) {
      Vue.set(state, 'loading', loading)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateRefreshAttempted (state, refreshAttempted) {
      Vue.set(state, 'refreshAttempted', refreshAttempted)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateShowSecret (state, showSecret) {
      Vue.set(state, 'showSecret', showSecret)
    }
  }
}
