<template>
  <div />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { setBankidStartUrl } from '../../utils/set-bankid-start-url'

  export default {
    name: 'AuthHandler',
    data: () => ({
      secretPoll: null,
      secretPollInterval: 1000,
      collectPoll: null,
      collectPollInterval: 2000,
      timeout: null,
      timeoutAge: 180000,
      AUTHENTICATION: {
        POLL_SECRET: true,
        POLL_EID: true,
        REDIRECT_EID: false,
        VERIFY_EID: false
      }
    }),
    computed: {
      ...mapGetters('form', ['getForm']),
      ...mapGetters('authentication', ['getAuthentication']),
      ...mapGetters('misc', ['getIsDesktop']),
      autoStartToken () {
        return this.getAuthentication?.autoStartToken
      }
    },
    mounted () {
      if (this.AUTHENTICATION.VERIFY_EID) this.verifyEid()

      this.EVENT_BUS.$on('authentication:initiate', (type) => this.initiate(type))
      this.EVENT_BUS.$on('authentication:cancel', this.cancel)
      this.EVENT_BUS.$on('authentication:kill', this.kill)
      this.EVENT_BUS.$on('logout:redirect', this.redirectOnLogout)
      this.EVENT_BUS.$on('authentication:bankid:open', this.openBankId)

      window.addEventListener('elskling-menu:logout', this.logout)
      window.addEventListener('elskling-menu:logout:not-authorized', this.autoLogout)
      window.addEventListener('elskling-menu:logout:login', this.loggedOut)
      window.addEventListener('elskling-menu:get-user', () => this.fetchUser())
    },
    destroyed () {
      this.EVENT_BUS.$off('authentication:initiate')
      this.EVENT_BUS.$off('authentication:cancel')
      this.EVENT_BUS.$off('authentication:kill')
      this.EVENT_BUS.$off('logout:redirect')
      this.EVENT_BUS.$on('authentication:bankid:open')

      window.removeEventListener('elskling-menu:logout', this.logout)
      window.removeEventListener('elskling-menu:logout:not-authorized', this.autoLogout)
      window.removeEventListener('elskling-menu:logout:login', this.loggedOut)
      window.removeEventListener('elskling-menu:get-user', this.getUser)
    },
    methods: {
      ...mapActions('authentication', [
        'initiateEid',
        'collectEid',
        'cancelEid',
        'createOauth',
        'secretEid',
        'resetAuthentication',
        'autoLogout',
        'logout'
      ]),
      ...mapActions('messages', ['setModalMessage', 'setStatusMessage', 'resetMessages']),
      ...mapActions('user', ['fetchUser']),
      ...mapActions('form', ['setField', 'validateFields']),
      async initiate (type) {
        this.killSecretPoll()
        this.killCollectPoll()

        if (!this.getForm.visibleFieldsAreValid) return

        const response = await this.initiateEid()
        if (!response) return

        if (this.AUTHENTICATION.POLL_SECRET && this.getIsDesktop) {
          await this.secretEid()
          await this.pollSecret()
        }

        if (this.AUTHENTICATION.POLL_EID) {
          if (!this.getIsDesktop) {
            await this.openBankId()
          }

          await this.pollCollect()
        }

        if (this.AUTHENTICATION.REDIRECT_EID) {
          if (!response) return

          setTimeout(() => {
            window.location.href = response
          }, 300)
        }
      },
      pollEid () {
        this.kill()

        this.poll = setInterval(async () => {
          const complete = await this.collectEid()

          if (complete) {
            this.kill()
            await this.createAuth()
          } else if (complete === false || !this.getAuthentication.token) {
            this.kill()
          }
        }, this.pollInterval)

        this.setTimeout()
      },
      async verifyEid () {
        const resolved = await this.collectEid()
        if (resolved) await this.createAuth()
      },
      async createAuth (type) {
        const resolved = await this.createOauth(type)
        if (!resolved) return

        this.redirectOnLogin()
      },
      setTimeout () {
        this.timeout = setTimeout(() => {
          this.setModalMessage({
            group: 'authentication',
            name: 'expiredTransaction'
          })

          if (!this.secretPoll || !this.collectPoll) return

          this.killSecretPoll()
          this.killCollectPoll()
        }, this.timeoutAge)
      },
      async loggedOut () {
        this.kill()
        await this.logout()
        this.resetMessages()
        this.EVENT_BUS.$emit('login:show')
      },
      kill () {
        clearInterval(this.poll)
        this.poll = null
      },
      cancel () {
        this.kill()
        this.cancelEid()
      },
      redirectOnLogout () {
        if (location.pathname.includes(this.MY_PAGES_URL)) {
          window.location.href = '/'
        }
      },
      redirectOnLogin () {
        if (!location.pathname.includes(this.FORM_PATH_KEYWORD) || location.pathname.includes(this.FORM_SIGNED_PATH_KEYWORD)) {
          window.location.href = this.MY_PAGES_URL
        }
      },
      killSecretPoll () {
        clearInterval(this.secretPoll)
        this.secretPoll = null

        clearTimeout(this.timeout)
        this.timeout = null
      },
      killCollectPoll () {
        clearInterval(this.collectPoll)
        this.collectPoll = null

        clearTimeout(this.timeout)
        this.timeout = null
      },
      async pollCollect () {
        this.killCollectPoll()

        this.collectPoll = setInterval(async () => {
          const complete = await this.collectEid()

          if (complete) {
            this.killCollectPoll()
            await this.createAuth()
          } else if (complete === false && !this.getAuthentication.token) {
            this.killCollectPoll()
          }
        }, this.collectPollInterval)

        this.setTimeout()
      },
      async pollSecret () {
        this.killSecretPoll()

        this.secretPoll = setInterval(async () => {
          const complete = await this.secretEid()

          if (complete === false && !this.getAuthentication.token) {
            this.killSecretPoll()
          }
        }, this.secretPollInterval)

        this.setTimeout()
      },
      async openBankId () {
        try {
          window.location.href = setBankidStartUrl(this.autoStartToken)
        } catch (e) {}
      }
    }
  }
</script>
