import applications from './applications'
import properties from './properties'
import features from './features'
import form from './form'
import fragments from './fragments'
import router from './router'
import utils from './utils'
import user from './user'
import variations from './variations'
import authentication from './authentication'
import messages from './messages'
import misc from './misc'

export default (country) => {
  return {
    applications,
    properties,
    features,
    ...(country === 'se' ? { form: form(country) } : undefined),
    fragments,
    messages,
    router,
    utils,
    user,
    variations,
    authentication,
    misc
  }
}
