const devalue = require('@nuxt/devalue')

/**
 * @param html
 * @param appName
 * @param sentryInfo
 * @returns {Promise<*>}
 */
export async function addSentryScript ({ html, appName, sentryInfo }) {
  if (!html || !appName) return

  return html
    .replace('<!--sentry-outlet-->', sentryInfo
      ? `
          <script>
            if (!(typeof window.__VITE_SENTRY_ROUTES__ === 'object' && !Array.isArray(window.__VITE_SENTRY_ROUTES__) && window.__VITE_SENTRY_ROUTES__ != null)) {
                window.__VITE_SENTRY_ROUTES__ = {}
            }

            window.__VITE_SENTRY_ROUTES__[${devalue(appName)}] = [{
              dsn: ${devalue(sentryInfo?.dsn)},
              release: ${devalue(sentryInfo?.release)}
            }]
          </script>
        `
      : ''
    )
}
