export default {
  LANG: 'fi-fi',
  BASE_URL: 'https://www.zmarta.fi',
  INPUT_LENGTH: {
    ZIP_CODE: {
      REQUIRED: 5
    }
  },
  FORM_PAGE: {
    zmarta: {
      consumer: '/sahko/vaihe/1'
    },
    elskling: {
      consumer: '/strom/sammenlign/1'
    }
  },
  AUTHENTICATION: {
    POLL_EID: false,
    REDIRECT_EID: true,
    AUTO_START_EID: false,
    VERIFY_EID: true
  }
}
