import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {},

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getFeatures: state => state
  },

  mutations: {
    setFeatures (state, features) {
      for (const key in state) {
        delete state[key]
      }
      for (const key in features) {
        Vue.set(state, key, features[key])
      }
    }
  }
}
