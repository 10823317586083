export default {
  customer: {
    saving: 'Sparar uppgifter'
  },
  authentication: {
    default: 'Identifiering eller underskrift pågår.',
    cancelling: 'Avbryter identifieringen.',
    confirming: 'Bekräftar identifieringen.',
    outstandingTransaction: 'Starta BankID-appen på dator eller mobil enhet',
    noClient: 'Starta BankID-appen på dator eller mobil enhet',
    userSign: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under.',
    started: 'Skriv in säkerhetskoden till ditt BankID i din mobiltelefon eller surfplatta och välj Legitimera / Skriv under eller välj att avbryta.',
    loggingIn: 'Loggar in',
    loggingOut: 'Loggar ut'
  }
}
