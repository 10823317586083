export default {
  zmarta: {
    multipleSteps: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/hae',
      robots: 'noindex, nofollow'
    },
    multipleStepsAddCo: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/jatka-co',
      robots: 'noindex, nofollow'
    },
    multipleStepsContinueApplication: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/jatka',
      robots: 'noindex, nofollow'
    }
  },
  broker: {
    multipleSteps: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/hae',
      robots: 'noindex, nofollow'
    },
    multipleStepsAddCo: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/jatka-co',
      robots: 'noindex, nofollow'
    },
    multipleStepsContinueApplication: {
      title: 'Kilpailuta lainasi helposti netistä Zmartan välityksellä',
      description: 'Kilpailuta laina ja kulutusluotto Zmartan välityksellä! Zmarta on uudenlainen rahoituspalvelu, jonka avulla voit alentaa lainojesi kuluja. Lue lisää!',
      keywords: '',
      canonical: '/lainaa-rahaa/yhdista-lainat/jatka',
      robots: 'noindex, nofollow'
    }
  }
}
