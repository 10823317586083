<template>
  <div />
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'EventListener',
    mounted () {
      window.addEventListener('resize', this.onResize)

      this.onResize()
    },
    destroyed () {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      ...mapActions('misc', ['setMisc']),
      onResize () {
        const width = window.innerWidth ||
          document.documentElement?.clientWidth ||
          document.body?.clientWidth

        this.setMisc({ isMobile: (width <= 768) })
        this.setMisc({ isTablet: (width > 768 && width <= 992) })
        this.setMisc({ isDesktop: (width > 992) })
      }
    }
  }
</script>
