<template>
  <zg-modal
    :visible="visible"
    :show-close="showClose"
    :fullscreen="!getIsDesktop"
    @close="close"
  >
    <template #title>
      {{ title }}
    </template>

    <template #text>
      <div class="login-modal">
        <form
          novalidate
          @submit.prevent="submit"
        >
          <div
            v-if="notice || message || error"
            class="information"
          >
            <div
              v-if="notice"
              key="notice"
              class="notice"
              v-html="notice"
            />

            <div
              v-else-if="message"
              key="message"
              class="message"
              v-html="message"
            />

            <div
              v-else-if="error"
              key="error"
              class="error"
              v-html="error"
            />
          </div>

          <div
            v-if="hasSlot('loading')"
            class="loading"
          >
            <slot name="loading" />
          </div>

          <div
            v-if="hasSlot('buttons')"
            class="buttons"
          >
            <slot name="buttons" />
          </div>
        </form>
      </div>
    </template>
  </zg-modal>
</template>

<script>
  import { ZgModal } from '@zc/components/ZgModal'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LoginModal',
    components: {
      ZgModal
    },
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: false
      },
      title: {
        type: String,
        required: false,
        default: null
      },
      notice: {
        type: String,
        required: false,
        default: null
      },
      message: {
        type: String,
        required: false,
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      showClose: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    computed: {
      ...mapGetters('misc', ['getIsDesktop'])
    },
    methods: {
      ...mapActions('authentication', ['resetLoading']),
      hasSlot (name) {
        return !!this.$slots?.[name]
      },
      submit () {
        this.$emit('submit')
      },
      close () {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    width: 100%;

    @include mq(medium) {
      width: rem(400px);
      margin: 0 rem(48px);
      padding: rem(12px) 0;
    }

    & > p {
      text-align: center;
      margin: 0;
    }
  }

  .information {
    .notice,
    .message,
    .error {
      width: 100%;
      font-weight: font-weight(bold);
      text-align: center;

      :deep(span.code) {
        @include type-body-xs;

        display: block;
        font-weight: font-weight(bold);
        margin: 0;
      }
    }

    .message {
      color: color(success-500);
    }

    .notice,
    .error {
      color: color(error-500);
    }
  }

  .buttons {
    text-align: center;
    width: 100%;

    :deep(.zg-button) {
      max-width: 100%;
      min-width: 100%;
      margin: 0 0 rhythm(small);

      &:last-child {
        margin: 0;
      }
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
  }
</style>
