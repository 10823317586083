export default {
  title: 'Prisbevakningar',
  contractInfo: {
    productType: {
      MOVING: 'Rörligt pris',
      FIX: 'Fastpris'
    },
    timeTiedUp: {
      All: 'Alla bindningstider',
      UpToOneYear: 'Kortare än 1 års bindningstid',
      OneYear: '1 års bindningstid',
      TwoYears: '2 års bindningstid',
      ThreeYears: '3 års bindningstid',
      MoreThanThreeYears: 'Längre än 3 års bindningstid'
    },
    movingSpan: {
      ALL: 'Rörligt pris och timpris',
      HOURLY: 'Timpris',
      MONTHLY: 'Rörligt pris'
    },
    notification: {
      MOVING: {
        label: 'Uppdatera mig: ',
        interval: {
          weekly: 'En gång i veckan',
          monthly: 'En gång i månad',
          biAnnual: 'En gång i halvåret'
        }
      },
      FIX: {
        label: 'Bevakat pris: ',
        currency: {
          kwh: 'öre/kWh',
          monthly: 'kr i månaden',
          yearly: 'kr/år'
        }
      }
    },
    zipCode: 'Postnummer'
  },
  actions: {
    delete: 'Ta bort bevakning',
    view: 'Gå till bevakningen'
  },
  error: 'Något blev fel, försök igen senare'
}
