<template>
  <div class="login-loader">
    <div
      :class="['loader', {
        'loader--eid': true
      }]"
    >
      <zg-icon
        :icon="providerIcon"
        class="eid"
      />

      <zg-loader
        :linear="true"
        :color="providerColor"
      />
    </div>

    <p
      ref="statusText"
      :style="{ 'max-height': `${statusHeight}px`}"
    >
      {{ status }}
    </p>
  </div>
</template>

<script>
  import { ZgIcon } from '@zc/components/ZgIcon'
  import { ZgLoader } from '@zc/components/ZgLoader'

  export default {
    name: 'LoginLoader',
    components: {
      ZgIcon,
      ZgLoader
    },
    props: {
      status: {
        type: String,
        required: false,
        default: null
      }
    },
    data: () => ({
      statusHeight: 0
    }),
    computed: {
      providerIcon () {
        return `bank-id-${this.COUNTRY}`
      },
      providerColor () {
        if (this.SWEDEN) return 'bankid-se'
        if (this.NORWAY) return 'bankid-no'
        if (this.FINLAND) return 'bankid-fi'
        return 'brand-500'
      }
    },
    watch: {
      status: function () {
        this.setStatusHeight()
      }
    },
    mounted () {
      this.setStatusHeight()
    },
    methods: {
      setStatusHeight () {
        this.$nextTick(() => {
          this.statusHeight = this.$refs.statusText && this.$refs.statusText.scrollHeight
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(12px);

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loader--eid {
      position: relative;
      width: rem(120px);
      height: rem(120px);

      :deep(svg) {
        width: 100%;
        height: 100%;

        circle {
          stroke-width: 3;
        }
      }

      .eid {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-shrink: 0;
        width: rem(56px);
        height: rem(56px);
      }
    }

    p {
      @include type-body-m;

      font-size: rem(16px);
      line-height: rem(20px);
      text-align: center;
      margin: rhythm() 0;
      overflow: hidden;
      transition: all 0.3s ease;
    }
  }
</style>
