export default {
  LANG: 'sv-se',
  BASE_URL: 'https://www.zmarta.se',
  INPUT_LENGTH: {
    YEARLY_CONSUMPTION: {
      MAX: 6
    },
    ADDRESS: {
      MAX: 95
    },
    ZIP_CODE: {
      REQUIRED: 5
    },
    NET_ID: {
      REQUIRED: 3
    },
    FACILITY_ID: {
      REQUIRED: 18
    }
  },
  FORM_PAGE: {
    zmarta: {
      consumer: '/el/jamfor/1'
    },
    elskling: {
      consumer: '/privat/jamfor/1',
      business: '/foretag/jamfor/1'
    }
  },
  AUTHENTICATION: {
    POLL_EID: true,
    REDIRECT_EID: false,
    AUTO_START_EID: true,
    VERIFY_EID: false
  },
  FORM_PATH_KEYWORD: 'jamfor/',
  FORM_SIGNED_PATH_KEYWORD: 'jamfor/4',
  MY_PAGES_URL: '/mina-sidor'
}
