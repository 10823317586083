import { getProp, isArray, isString, size } from '@ocp-zmarta/zmarta-cl'

/**
 * Gets error content
 * @param {array|object|undefined} errors
 * @returns {Object}
 */
export function getErrorName (errors) {
  let error

  if (isArray(errors) && size(errors)) {
    error = getProp(errors[0], 'name')
  } else if (isString(errors) && size(errors)) {
    error = errors
  } else {
    error = null
  }

  return error
}

/**
 * @param {string} errorCode
 * @returns {boolean}
 */
export function notAuthError (errorCode) {
  return !['JWT_INVALID', 'JWT_EXPIRED', 'JWT_MISSING'].includes(errorCode)
}
