<template>
  <zg-transition-fade>
    <div
      v-if="getUser.loggedIn && getScreenWidth === 'desktop'"
      class="user-container test-class"
      @mouseenter="showDropdown = true"
      @mouseleave="showDropdown = false"
    >
      <zg-button
        :class="['user', { 'user--active': showDropdown }]"
        link
        :icons="{ right: 'chevron', rightRotation: showDropdown ? '180' : '0' }"
        :loading="!isLoaded"
      >
        {{ userName }}
      </zg-button>

      <zg-transition-fade>
        <ul v-show="showDropdown">
          <li>
            <a
              :href="MY_PAGES_URL"
              :class="['link-item', {
                'link-item--current': getPath === MY_PAGES_URL
              }]"
            >
              {{ contentMyPages }}
            </a>
          </li>

          <li @click="logout">
            <span class="link-item">
              {{ contentLogout }}
              <zg-icon
                icon="logout"
                size="xxsmall"
              />
            </span>
          </li>
        </ul>
      </zg-transition-fade>
    </div>

    <zg-button
      v-else-if="!getUser.loggedIn"
      :class="{ 'mobile-button' : getScreenWidth !== 'desktop' }"
      outline
      :size="getScreenWidth === 'desktop' ? 'medium' : 'small'"
      :icons="{ right: 'bank-id-se'}"
      :loading="!isLoaded"
      :round="getScreenWidthPx < 350"
      @click="showLogin"
    >
      {{ contentLogin }}
    </zg-button>
  </zg-transition-fade>
</template>

<script>
  import { getProp } from '@ocp-zmarta/zmarta-cl'
  import { ZgButton } from '@zc/components/ZgButton'
  import { ZgIcon } from '@zc/components/ZgIcon'
  import { ZgTransitionFade } from '@zc/components/ZgTransitionFade'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LoginButton',
    components: {
      ZgButton,
      ZgIcon,
      ZgTransitionFade
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        isLoaded: false,
        showDropdown: false
      }
    },
    computed: {
      ...mapGetters('user', ['getUser']),
      ...mapGetters('utils', ['getScreenWidth', 'getScreenWidthPx']),
      ...mapGetters('router', ['getRoute']),
      userName () {
        if (getProp(this.getUser, 'firstName') && getProp(this.getUser, 'lastName')) {
          return `${getProp(this.getUser, 'firstName')} ${getProp(this.getUser, 'lastName')}`
        } else {
          return ''
        }
      },
      contentLogin () {
        return getProp(this.content, 'microCopy', 'resources')?.find(r => r.contentId === '53DJL3OuGYs7BqKVQEUVa0')?.value
      },
      contentLogout () {
        return getProp(this.content, 'microCopy', 'resources')?.find(r => r.contentId === '4ZbrrW15jkgclsGnnwrgdT')?.value
      },
      contentMyPages () {
        return getProp(this.content, 'microCopy', 'resources')?.find(r => r.contentId === '6GI2bi3DpKbp3zM90ZNXiP')?.value
      },
      getPath () {
        return this.getRoute?.path
      }
    },
    async mounted () {
      try {
        await this.fetchUser()
      } catch (e) {
        console.error(e)
      }
      this.isLoaded = true
    },
    methods: {
      ...mapActions('messages', ['resetMessages']),
      ...mapActions('user', ['fetchUser']),
      showLogin () {
        this.EVENT_BUS.$emit('login:show')
      },
      logout () {
        window.dispatchEvent(new window.Event('elskling-menu:logout'))
      },
      goToMyPages () {
        if (!location.pathname.includes(this.MY_PAGES_URL)) {
          window.location.href = this.MY_PAGES_URL
        }
      },
      getProp
    }
  }
</script>

<style lang="scss" scoped>
  .zg-button.zg-button {
    &--small.mobile-button {
      padding: 0 rhythm(xsmall);
      min-width: rhythm(10);
      min-height: rhythm("medium");
      border-width: 1px;
    }
  }

  .user-container {
    height: 100%;
    padding-top: rhythm("xsmall");
  }

  .user {
    height: inherit;
    padding-right: rhythm("half");
    padding-bottom: rhythm("xsmall");
    padding-left: rhythm("half");
    border-radius: 20px 20px 0 0;

    &--active,
    &:hover {
      background-color: color(beige);
    }
  }

  ul {
    list-style: none;
    background-color: color(beige);
    border-radius: 0 0 20px 20px;
    margin-bottom: 0;
    padding: 0;
  }

  li {
    @include type-title-xs;

    display: flex;
    align-items: center;
    column-gap: rhythm("xsmall");
    margin-bottom: 0;
    cursor: pointer;
    white-space: nowrap;

    .link-item {
      margin: rhythm("small") rhythm("small") rhythm("small") 0;
      padding: rhythm("small") rhythm("base");
      border-bottom: none;
      display: inline-flex;
      column-gap: rhythm("xsmall");
      align-items: center;

      &--current {
        background-color: color(white);
        border-radius: 0 6.1875rem 6.1875rem 0;
      }
    }

    &:first-child {
      border-bottom: 1px solid color(gray-200);
    }

    &:last-child {
      @include type-title-xxs;
    }

    &:hover,
    a:hover {
      color: color(brand-500);

      svg {
        color: color(brand-500);
      }
    }
  }
</style>
