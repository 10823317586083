<template>
  <div
    v-if="isActive"
    class="site-information"
  >
    <img
      :src="iconImg"
      class="img"
    >
    <p>
      Nyhet! Nu kan du jämföra försäkringar hos Elskling.
    </p>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import icon from '@images/tips_and_updates.svg'

  export default {
    name: 'SiteInformation',
    computed: {
      ...mapGetters('features', ['getFeatures']),
      iconImg () {
        return icon
      },
      isActive () {
        return this.getFeatures.siteinformation?.enabled
      }
    }
  }
</script>

<style lang="scss" scoped>
.site-information {
  width: 100%;
  position: fixed;
  top: rem(56px);
  background-color: color(brand-100);
  display: flex;
  gap: rhythm(xsmall);
  align-items: center;
  padding: rem(12px);

  @include mq("medium") {
    justify-content: center;
  }

  p {
    font-family: pantograph-pro, pantograph-pro-fallback, arial, sans-serif;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
