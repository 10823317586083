import form from './form'
import modalMessages from './modal-messages'
import myPages from './my-pages'
import statusMessages from './status-messages'
import successMessages from './success-messages'
import zipCodeForm from './zip-code-form'
import priceWatchUnsubcribe from './price-watch-unsubscribe'

export default {
  form,
  modalMessages,
  myPages,
  statusMessages,
  successMessages,
  zipCodeForm,
  priceWatchUnsubcribe
}
