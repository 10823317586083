import requests from '../../requests'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async getFragmentHtml (_, { url, ssi, ssr }) {
      try {
        return await requests.internal.fragment(url, ssi, ssr)
      } catch (e) {
        return null
      }
    }
  }
}
