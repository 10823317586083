var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":_vm.containerId}},[_c('lazy-hydrate',{attrs:{"never":""}},[_c('page-meta',{key:"page-meta"})],1),_vm._v(" "),(_vm.showNav && _vm.getDomain === 'elskling')?_c('elskling-header',{attrs:{"content":_vm.routeMenu,"product":_vm.product}}):(_vm.showNav)?_c('fragment',{key:"header",attrs:{"name":"header","pre-render":""}}):_vm._e(),_vm._v(" "),_c('section',{class:[
      'page',
      {
        'page-nav': _vm.showNav,
        'background-color--beige': _vm.beigeBackground,
      },
    ]},[_c('lazy-hydrate',{attrs:{"when-idle":""}},[(_vm.showNav)?_c('div',{key:"bg",class:[
          'page__bg',
          {
            page__siteinformation: _vm.hasSiteInformation,
          },
        ]}):_vm._e()]),_vm._v(" "),_c('routes',{key:"route",attrs:{"container-id":_vm.containerId},on:{"loaded":_vm.loaded}})],1),_vm._v(" "),(_vm.showNav && _vm.getDomain === 'elskling')?_c('elskling-footer',{attrs:{"content":_vm.routeMenu,"product":_vm.product,"beige-background":_vm.beigeBackground}}):(_vm.showNav)?_c('fragment',{key:"footer",attrs:{"name":"footer","pre-render":""}}):_vm._e(),_vm._v(" "),_c('event-listener')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }