export default {
  authentication: {
    default: {
      title: 'BankID',
      text: 'Ett oväntat fel inträffade. Vänligen försök igen senare.'
    },
    expiredTransaction: {
      title: 'BankID',
      text: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.'
    },
    certificateErr: {
      title: 'BankID',
      text: 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.'
    },
    userCancel: {
      title: 'BankID',
      text: 'Identifieringen avbröts från BankID-appen.'
    },
    userForceCancel: {
      title: 'BankID',
      text: 'Identifieringen avbröts.'
    },
    startFailed: {
      title: 'BankID',
      text: 'Inloggningen avbröts, eller tog för lång tid. Av säkerhetsskäl finns det en tidsbegränsning.'
    },
    alreadyInProgress: {
      title: 'BankID',
      text: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.'
    },
    cancelled: {
      title: 'BankID',
      text: 'Åtgärden avbruten. Försök igen.'
    },
    requestTimeout: {
      title: 'BankID',
      text: 'Det har inträffat ett internt tekniskt fel. Försök igen.'
    },
    maintenance: {
      title: 'BankID',
      text: 'Det har inträffat ett internt tekniskt fel. Försök igen.'
    },
    internalError: {
      title: 'BankID',
      text: 'Det har inträffat ett internt tekniskt fel. Försök igen.'
    },
    noCustomer: {
      title: 'BankID',
      text: 'Vi kunde inte hitta några existerande kunduppgifter kopplade till dig.'
    }
  },
  customer: {
    default: {
      text: 'Ett oväntat fel inträffade. Vänligen försök igen senare.'
    }
  },
  api: {
    default: {
      text: 'Ett oväntat fel inträffade. Vänligen försök igen senare.'
    },
    UNSPECIFIED_ERROR: {
      text: 'Ett oväntat fel inträffade. Vänligen försök igen senare.'
    },
    NOT_AUTHORIZED: {
      text: 'Ett oväntat fel inträffade. Vänligen försök igen senare.'
    },
    SSN_NOT_WHITELISTED: {
      text: 'The used SSN is not whitelisted'
    }
  }
}
